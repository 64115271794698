import { Transition, TransitionChild } from "@headlessui/react";
import { Icon } from '@iconify/react/dist/iconify.js';
import { AlertCompProps } from '../../@types/@types.theme';



const AlertComp = ({
    color, isOpen=false, onClose, text, 
    icon, 
    subText, density="normal"
}: AlertCompProps) => {
    

    const iconColor =
		{
			success: "text-green-500",
			warning : "text-yellow-600",
			primary : "text-blue-600",
			danger : "text-red-600",
		}[color] || "text-green-500";
    
    const textColor =
		{
			success: "text-green-700",
			warning : "text-orange-700",
			primary : "text-blue-700",
			danger : "text-red-700",
		}[color] || "text-green-700";
    
    // const bgColor =
	// 	{
	// 		success: "bg-green-500",
	// 		warning : "bg-orange-600",
	// 		primary : "bg-blue-600",
	// 	}[color] || "bg-green-500";
    
    const borderColor =
		{
			success: "border-l-green-500",
			warning : "border-l-yellow-600",
			primary : "border-l-blue-600",
			danger : "border-l-red-600",
		}[color] || "border-l-green-500";

    const alertDensity = {
        tight: "py-2",
        normal: "py-3",
        loose: "py-4",
    }[density] || "py-3"

    


    return(
        <Transition show={isOpen} 
            className='alert fixed top-[35px] right-[30px] z-[51] min-w-[300px] max-w-[400px]'
            as="div"
        >
            <TransitionChild
                className={`flex flex-row  bg-gray-50 rounded  shadow-md border border-gray-200 border-l-0`}                
                enter="transition ease-in-out duration-500 transform"
                enterFrom="translate-x-full"
				enterTo="translate-x-0"
                leave="transition ease-in-out duration-700 transform"
				leaveFrom="translate-x-full"
				leaveTo="-translate-x-0"
                as="div"
            >
                <div className={`${iconColor} border-l-2 ${borderColor} rounded-l flex items-center justify-center ${alertDensity} px-4`}>
                    { icon ? icon : 
                        <Icon icon="zondicons:exclamation-outline" />
                    }
                </div>
                <div className={`pr-5 ${alertDensity}`}>
                    <div>{text}</div>
                    { subText &&
                        <div className='text-[13px]'>{subText}</div>
                    }
                </div>
                <button
                    className={`absolute -top-2 -right-2 bg-white border border-gray-300 px-1 py-[5px] ${textColor} text-[12px] rounded-full`}
                    onClick={onClose}
                >                    
                    <Icon icon="iconamoon:sign-times-bold" fontSize={14}/>
                </button>
            </TransitionChild>            
        </Transition>
    )
}

export default AlertComp;