import { useCallback, useEffect, useState } from "react";
import { useNavigate,  useSearchParams } from "react-router-dom";
import { config } from "../../../constants/config";
import { useGlobalFunction } from "../../../hooks/globalFunction";
import api from "../../../constants/api";
import { useCookies } from "react-cookie";


interface formDataProps {
    [key: string] : any
}

export function useConfirmEmail(){    
    const { alertValue, setAlertValue, handleCloseAlert, isLoading, setIsLoading} = useGlobalFunction();

    const [searchParams] = useSearchParams();
    const client_id = searchParams.get('client_id')
    const signature = searchParams.get('signature')
    const params_token = searchParams.get('params_token')
    const navigate = useNavigate();    



    const [view, setView] = useState('') // success | register
    // const navigate = useNavigate();
    const [cookies, setCookie] = useCookies([config.cookiesName]);    
    const [formData, setFormData] = useState<formDataProps>({
        nama : {
            name : 'nama',
            type: 'text',
            label : 'Nama',
            value : '',
            require : true,
            showError : false,
            show: true,
            readOnly : true,
        },
        email : {
            name : 'email',
            type: 'text',
            label : 'Email',
            value : '',
            require : true,
            showError : false,
            show: true,
            showPassword : false,
            readOnly : true,
        },
        password : {
            name : 'password',
            type: 'password',
            label : 'Password',
            value : '',
            require : true,
            showError : false,
            show: true,
            showPassword : false,            
        },
        confirm_password : {
            name : 'confirm_password',
            type: 'password',
            label : 'Confirm Password',
            value : '',
            require : true,
            showError : false,
            show: true,
            showPassword : false,
        }
    })
    
    const fetchData = useCallback(async(client_id:string, signature: string, params_token: string) => {
        setIsLoading(true);
        try{            
            let tmp = {
                'client_id' : client_id,
                'signature' : signature,
                'params_token' : params_token
            }

            let newForm = new FormData()
            newForm.append('formData', JSON.stringify(tmp))
            const res = await api.post(`${config.endPoint.confirmEmailTenant}`, newForm).then((res: any) => res.data);
            if (res){
                setFormData((formData:any) => ({...formData,
                    nama : {...formData.nama, value : res.results?.nama},
                    email : {...formData.email, value : res.results?.email},
                }))
            }            

            setView('success')
            setIsLoading(false);          
        }catch(error: any){            
            setIsLoading(false);          
            const { response } = error;
            setView(response?.data?.view)
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }

            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])
    
    useEffect(() => {
        if (client_id && signature && params_token){
            fetchData(client_id, signature, params_token);
        }
    },[fetchData, client_id, signature, params_token])

    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setFormData((formData:any) => ({
            ...formData,
            [name]: { ...formData[name], value: value }
        }))
    }

    const handleBlur = (e:any) => {
        const { name, value } = e.target;
        if (formData[name].require && value === ''){
            setFormData((formData:any) => ({
                ...formData,
                [name]: { ...formData[name], showError: true }
            }))
        }else{
            setFormData((formData:any) => ({
                ...formData,
                [name]: { ...formData[name], showError: false }
            }))
        }
    }

    const handleShowPassword = (name:any) => {        
        setFormData((formData:any) => ({
            ...formData,
            [name]: {
                ...formData[name],
                showPassword: !formData[name].showPassword,
                type: !formData[name].showPassword ? 'text' : 'password'
            }
        }))
    }

    const handleSubmit = async() => {
        // console.log('handleSUbmit >>>>>>');
        // return
        try{
            let obj = {...formData}
            let countError = 0;
            let tmp :any = {}
            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    post.showError= true;
                    countError++;
                }else{
                    tmp[index] = post.value
                }
                    return true;
            })
            
            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Error',
                    subText: 'Lengkapi data',
                    color: 'danger'
                }))
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            let newForm = new FormData();
            
            
            tmp['client_id'] = client_id;
            tmp['signature'] = signature;
            tmp['params_token'] = params_token;
            
            newForm.append('formData', JSON.stringify(tmp));
            const res = await api.post(`${config.endPoint.createPasswordTenant}`, newForm).then((res:any) => res.data);
            console.log('res create password is >>>>>', res);            
            if (res){
                let tmpcookie = {
                    'token' : res.results.token, 
                    // 'user_info' : res.data.user_info,
                    'is_superuser' : false,
                    // 'development' : res.development,
                    // 'user_perm' : res.user_perm,
                    'is_login' : true
                };
                sessionStorage.setItem('nextUrl', res.results.next_url)
			    setCookie(config.cookiesName, tmpcookie, { path: '/', maxAge: config.expiresSession });                     
                navigate('/create-password-success')
                // console.log('apa sih >>>>>>>>>>>>>>>>>>.')
                return window.location.href = '/create-password-success'                
            }
        }catch(error:any){
            setIsLoading(false);            
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }

            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, config.timeOutValue)
        }
    }

    return {
        alertValue, setAlertValue, handleCloseAlert,
        formData, setFormData, handleChange, handleBlur, handleShowPassword,
        handleSubmit, view, setView, isLoading, cookies
    }
}