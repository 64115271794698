import { useNavigate } from 'react-router-dom';
import '../../../assets/styles/login.css'

const SuccessPage = () => {
    const navigate = useNavigate();
    const nextUrl = sessionStorage.getItem('nextUrl') || null;

    const handleMasuk = () => {
        if (nextUrl){
            window.location.href = nextUrl
        }else{
            navigate('/')
        }
    }

    console.log('nextUrl >>>>>', nextUrl)
    return(
        <div className='w-full h-screen bg-white flex flex-col items-center font-poppins px-[20px] '>
            <img src={require('../../../assets/images/registersuccess.jpg')} alt="img" className='h-[60%] object-contain'/>
            <div className='text-[3vmin]'>Password telah berhasil dibuat, sekarang anda bisa masuk ke aplikasi</div>
            <div className='relative flex flex-row justify-center mt-7'>
                <div className='btn-loader'></div>
                <button 
                    className='w-[180px] h-[60px] text-white bg-blue-600 rounded-lg text-[2vmin]  btn-countdown relative flex flex-row justify-center items-center'
                    onClick={handleMasuk}
                >
                    Masuk
                </button>                 
            </div>
        </div>
    )
}
export default SuccessPage;