import { Icon } from "@iconify/react";
import Card from "../../../components/Atoms/Card";
import PagePagination from "../../../components/Atoms/PagePagination";
import Tables from "../../../components/Atoms/Tables";
import Tooltip from "../../../components/Atoms/Tooltip";
import FormTextSingleComp from "../../../components/forms/FormTextSingleComp";
import { useTenant } from "./tenantFunction";
import Modal from "../../../components/Atoms/Modal";
import Button from "../../../components/Atoms/Button";
import AlertComp from "../../../components/Atoms/AlertComp";

interface TenantTenantPageProps {
    onClickRow? : (e:any) => void,
    query_type? : string
}

const TenantTenantPage = ({
    onClickRow,
    query_type=''
}: TenantTenantPageProps) => {    
    const { view, pageValue, handleCreate, handleSubmit, handleCancel,
        handleChangeSearch, handleSearch,
        listData, handlePaginate, formData, setFormData, isApprove, isActiveKontak,
        handleEdit,  handleSubmitDelete, handleDelete, modalValue,  setModalValue, handleCloseModal, isEdit,
        alertValue, handleCloseAlert
    } = useTenant({query_type: query_type});
    
    return(
        <>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            <Modal
                show={modalValue.show}
                setShow={() => {
                    setModalValue((modalValue: any) => ({...modalValue, show: false}))
                }}
                width='xl'
                persistent={false}
                btnClose={false}
            >
                <div className='py-[20px] px-[30px] border-b border-b-gray-200 font-bold'>
                    {modalValue.title}
                </div>
                <div className='py-[50px] px-[30px]'>
                    { modalValue.text }
                </div>
                <div className='py-[20px] px-[30px] border-t border-t-gray-200 flex flex-row justify-end gap-2'>
                    <Button color='danger' size='md' onClick={handleSubmitDelete}>Hapus</Button>
                    <Button color='base' size='md' onClick={handleCloseModal}>Cancel</Button>
                </div>
            </Modal>
            { !isApprove &&
                <div className='bg-amber-50 text-amber-700 rounded-lg flex flex-row  h-[80px] w-full mb-5 gap-5'>
                    <div className='w-[8px] h-full bg-amber-400 rounded-l-lg'></div>
                    <div className='flex flex-row justify-center items-center'>
                        { !isApprove && <div>Akun anda masih dalam proses persetujuan</div> }
                        { !isActiveKontak && <div>Akun anda dalam peninjauan kembali</div> }
                    </div>
                </div>
            }
            <Card
                isCreate={view === 'create'}
                isSearch={view === 'list'}
                searchValue={pageValue.search}    
                showCreateButton={isApprove}        
                handleCreate={handleCreate}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
                btnSaveText={isEdit ? "Update" : "Simpan"}
                btnCancelText={isEdit ? "Kembali" : "Cancel"}
            >
                { view === 'list' &&
                    <>                    
                        <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                    <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Apps Name</Tables.Header>                                
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Logout URL</Tables.Header>                                
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Action</Tables.Header>
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                { listData?.data?.length === 0 ?
                                    <Tables.Row>
                                        <Tables.Data colspan={4} className='text-center'>Belum ada data</Tables.Data>
                                    </Tables.Row>
                                    :
                                    <>
                                        {listData?.data?.map((post:any, index:number) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data onClick={() => onClickRow && onClickRow(post)} className={`${onClickRow && 'cursor-pointer'} text-right align-top`}>{pageValue.offset + index + 1}</Tables.Data>
                                                    <Tables.Data onClick={() => onClickRow && onClickRow(post)} className={`${onClickRow && 'cursor-pointer'} `}>{post.nama}</Tables.Data>
                                                    <Tables.Data onClick={() => onClickRow && onClickRow(post)} className={`${onClickRow && 'cursor-pointer'} `}>{post.logout_url}</Tables.Data>
                                                    <Tables.Data>
                                                        <div className='flex flex-row gap-1'>
                                                            <Tooltip
                                                                tooltip={'Edit'}
                                                            >
                                                                <button onClick={() => handleEdit(post)} className='p-1 bg-orange-500 text-white rounded'><Icon icon='line-md:edit' /></button>
                                                            </Tooltip>
                                                            <Tooltip
                                                                tooltip={'Hapus'}
                                                            >
                                                                <button onClick={() => handleDelete(post)} className='p-1 bg-red-500 text-white rounded'><Icon icon='fluent-mdl2:delete'  /></button>
                                                            </Tooltip>
                                                        </div>
                                                    </Tables.Data>
                                                </Tables.Row>
                                            )
                                        })}
                                    </>
                                }
                            </Tables.Body>
                        </Tables>
                        <div className='mt-5'>
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePaginate}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </div>
                    </>
                }
                { view === 'create' &&
                    <div className='grid grid-cols-1 md:grid-cols-2'>
                        <div>
                            <FormTextSingleComp
                                {...formData.nama}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...formData.public_key}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...formData.logout_url}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...formData.client_id}
                                    formData={formData}
                                    setFormData={setFormData}
                                    disabled={formData.client_id.readOnly}
                                />
                            </div>
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...formData.client_key}
                                    formData={formData}
                                    setFormData={setFormData}
                                    disabled={formData.client_key.readOnly}
                                />
                            </div>
                        </div>
                    </div>
                }
            </Card>
        </>
    )
}

export default TenantTenantPage;