import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../hooks/globalFunction";
import { config } from "../../../constants/config";
import api from "../../../constants/api";
import { pageObj } from "../../../hooks/pageObj";


export function useDefaultPermissions(){
    const { isLoading, setIsLoading, showLoading, closeLoading,
        pageValue, setPageValue, alertValue, setAlertValue, handleCloseAlert
    } = useGlobalFunction();
    const [listData, setListData] = useState({data: [], isLoading: false})

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading()
        try{
            let params : any = {
                offset : offset,
                limit : limit,
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.groupDefault}`, {params: params}).then((res: any) => res.data);
            if (res){
                setPageValue((pageValue: any) => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
                let tmp : any= []
                res.results.map((post: any) => {
                    let tmpObj : any = [{value : '', label : '-- Pilih Group --'}]
                    post.groups_obj.map((x: any) => tmpObj.push({value : x.id, label : x.name}));
                    let initForm =  {
                            name : 'group',
                            type: 'select',
                            label : 'Group',
                            value : post.group || '',
                            required : true,
                            showError : false,
                            show: true,
                            obj : tmpObj
                    }

                    let obj: any = {...post}
                    obj['formGroup'] = {...initForm}
                    tmp.push(obj);
                    return true;
                })                

                setListData(listData => ({...listData, isLoading: false, data: tmp}))
            }else{
                setListData(listData => ({...listData, isLoading: false}))
            }
            closeLoading()
        }catch(error: any){
            setListData(listData => ({...listData, isLoading: false}))
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])
    
    useEffect(() => {
        fetchData();
    },[fetchData])

    const handlePaginate = (page:any) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }


    const handleChange = async(e: any, post: any) => {
        setIsLoading(true);
        try{
            const {  value } = e.target;
            let tmp : any = {
                'kode' : post.kode,
                'group_id' : value
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            const res = await api.put(`${config.endPoint.groupDefault}${post.id}/`, newForm).then((res:any) => res.data);
            if (res){
                fetchData();
                setAlertValue((alertValue: any) => ({...alertValue,
                    show: true,
                    text : 'Successfully',
                    subText: 'Data berhasil di update',
                    color: 'success'
                }))

                setTimeout(() => {
                    handleCloseAlert();
                }, config.timeOutValue)
            }
        }catch(error:any){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }                
    }

    

    return {
        isLoading, setIsLoading, showLoading, closeLoading,
        pageValue, setPageValue, alertValue, setAlertValue, listData, handlePaginate,
        handleChange, handleCloseAlert
    }
}