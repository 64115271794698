import { useState } from "react";

type TimePickerProps = {
    onChange : (e: any) => void;
    timeValue? : string;
    name? : string;
}


const TimePickerComp = ({
    onChange,
    timeValue,
    name,
}: TimePickerProps) => {
    
    const [timeDisplay, setTimeDisplay] = useState('');
    const [showOption, setShowOption] = useState(false);
    console.log(timeDisplay)

    const arrayTime = [
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30"
    ]


    const handleFocus = () => {        
        setShowOption(true);
    }

    const handleOutOfFocus = (e: any) => {
        const {  value} = e.target;
        
        let nilai :any = value.split(":")
        let valHour : any = ""
        let valMinute : any = ""

        const getHour = (params: any) => {
            let tmpHour : any = ""
            if (params === ''){
                tmpHour = "0";                
            }else{
                if (isNaN(params)){
                    tmpHour = 0
                }else{
                    tmpHour = params
                    if (params > 23){
                        tmpHour = 23
                    }
                }
            }
            if (tmpHour.toString().length === 1){                
                tmpHour = `0${tmpHour}`.toString();
            }
            return tmpHour;
        }

        const getMinutes = (params: any) => {
            let tmpMinute : any = ""
            if (isNaN(params)){
                tmpMinute = 0                
            }else{
                tmpMinute = params
                if (params > 59){
                    tmpMinute = 59
                }
            }
            if (tmpMinute.toString().length === 1){
                tmpMinute = `0${tmpMinute}`
            }
            return tmpMinute;
        }

        if (nilai.length >= 2){                   
            valHour = getHour(nilai[0])            
            valMinute = getMinutes(nilai[1])                        
        }else if(nilai.length === 1){
            if (isNaN(value)){
                valHour = getHour(0)
                valMinute = getMinutes(0)
            }else{
                valHour = getHour(value)
                valMinute = getMinutes(0)
            }
        }
        setTimeDisplay(`${valHour}:${valMinute}`)        
        if (onChange){
            onChange(`${valHour}:${valMinute}`)
        }        
    }

    const handleChange = (e:any) => {
        const { value } = e.target;
        if (onChange){
            onChange(value);
        }
    }

    const handleSelectTime = (params:string) => {
        onChange(params);
        setShowOption(false);        
    }


    return(
        <div className='relative'>
            { showOption &&
                <div className='time-picker-overlay absolute' onClick={() => setShowOption(false)}>

                </div>
            }
            
            <input 
                className={`text-sm appearance-none 
                    block w-full bg-white
                    text-gray-700
                    border-gray-400 
                    rounded py-3 
                    px-2
                    border 
                    leading-tight 
                    focus:outline-1 
                    focus:outline-none 
                    focus:outline-blue-400 
                    focus:border-none`
                }
                onFocus={handleFocus}
                onBlur={handleOutOfFocus}                
                onChange={handleChange}
                value={timeValue}
                name={name}
            />
            
            { showOption &&
                <div className='absolute z-20'>
                    <div className='time-picker-content'>
                        <ul className='time-picker-list'>
                            { arrayTime.map((post, index) => {
                                return(
                                    <li 
                                        key={index} 
                                        className='time-picker-list-item'
                                    ><button onClick={() => handleSelectTime(post)}>{post}</button></li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            }
        </div>
    )
}


export default TimePickerComp;