import { Icon } from "@iconify/react";
import { HTMLProps, useRef } from "react";
import '../../assets/styles/login.css';
import DatePicker from "react-datepicker";

interface InputLoginProps {
    labelName: string;
    value: string;
    dateValue? : any,
    onChange: (e:any) => void;
    onChangeDate? : (e:any) => void,
    onBlur: (e:any) => void;
    rooClassName? : HTMLProps<HTMLElement>["className"];
    name : string;
    showPassword? : boolean;
    handleShowPassword? :  () => void;
    tipe? : string;
    readOnly? : boolean,
    obj? : any[]
}

const InputLoginComp = ({
    labelName,
    value,
    onChange,
    onBlur,
    rooClassName,
    name,
    showPassword=false,
    handleShowPassword,
    tipe="text",
    readOnly=false,
    obj=[],
    onChangeDate,
    dateValue = new Date()
}: InputLoginProps) => {
    const ref = useRef<HTMLInputElement>(null);
    
    return(
        <div className={`flex flex-col relative   ${readOnly ? 'bg-gray-200' : 'bg-white border border-gray-200'} rounded-md py-3 w-full px-4 form-input group ${rooClassName}`}>
            <label 
                htmlFor={name}        
                data-content={labelName}        
                className={`label-input  
                    ${readOnly ? 'label-input-disabled' : 'px-[5px]'}
                    transform 
                    transition-all 
                    absolute                     
                    flex 
                    items-center                                                           
                    text-xs 
                    -top-[8px]
                    left-[10px]                                 
                    bg-white                    
                    ${readOnly && 'bg-transparent'}
                `}
                    // ${readOnly && 'bg-gradient-to-b from-white to-gray-200'}
                
            >{labelName}</label>
            { ['text', 'password'].includes(tipe) &&
                <input
                    ref={ref}
                    className={`                                  
                        rounded-lg w-full text-left leading-tight 
                        focus:outline-1 
                        focus:outline-none 
                        block
                        peer
                        ${readOnly && 'bg-gray-200'}  
                    `}
                    id={name}
                    required
                    value={value}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    type={tipe}
                    readOnly={readOnly}  
                />
            }
            { tipe === 'select' &&
                <select 
                    onChange={onChange} 
                    onBlur={onBlur} 
                    name={name} 
                    value={value} 
                    id="countries"                     
                    className={`
                        rounded-lg w-full text-left leading-tight 
                        focus:outline-1 
                        focus:outline-none 
                        block
                        peer bg-white
                    `}>
                    {obj.map((post, index) => {
                        return (
                            <option className='bg-white' value={post.value} key={index}>{post.label}</option>
                        )
                    })}
                </select>
            }
            { tipe === 'date' &&
                <div className="relative w-full">
                    <DatePicker
                        selected={dateValue}
                        onChange={onChangeDate}
                        dateFormat={"dd-MM-yyyy"}
                        // dateFormat={"HH:mm"}
                        timeFormat={"HH:mm"}
                        showTimeSelect={false}
                        showTimeSelectOnly={false }
                        className={`rounded-lg w-full text-left leading-tight 
                            focus:outline-1 
                            focus:outline-none 
                            block
                            peer`
                        }
                    />
                </div>
            }
            { handleShowPassword &&
                <button className="absolute top-[13px] right-[10px]"  onClick={handleShowPassword}>                                        
                    <Icon icon={showPassword ? "ph:eye-closed-bold" : "ph:eye-bold"} className='text-gray-400 text-[20px]' />
                </button>
            }
        </div>
    )
}

export default InputLoginComp