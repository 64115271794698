import { ThemeContext } from "../../context/ThemeContext";
import { Transition, TransitionChild } from "@headlessui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { ModalProps, themeTypeProps } from "../../@types/@types.theme";




const Modal =  ({
	show,
	setShow,
	children,
	width = "auto",
	height = "auto",
	withHeader=  false,
	title='',
	// btnClose = true,
	persistent = false,
	isLoading = false
}: ModalProps) => {
    const {  themeSkin } = useContext(ThemeContext) as themeTypeProps;
	const [animateWiggle, setAnimateWiggle] = useState(false);

	const contentRef = useRef<any>();

	const onBackDropClick = () => {
		if (!persistent) {
			setShow(false);
		} else {			
			setAnimateWiggle(true);
		}
	};

    useEffect(() => {
		if (animateWiggle) {
			const content = contentRef.current;
			content.classList.add("animate-wiggle");
			setTimeout(() => {
				content.classList.remove("animate-wiggle");
				setAnimateWiggle(false);
			}, 200);

			return () => {
				content.classList.remove("animate-wiggle");
				setAnimateWiggle(false);
			};
		}
	}, [animateWiggle]);

    const modalWidth =
		{
			xs: "320px",
			sm: "480px",
			md: "640px",
			lg: "800px",
			xl: "960px",
			full: "100%",
			auto: "auto",
		}[width] || width;

    const modalHeight =
		{
			'full': "100%",
			'auto' : 'auto',
		}[height] || height;

    return (        
        <Transition			
			as="div"
			show={show}
			className="fixed inset-0 z-[50] p-5 flex flex-col items-center justify-center overflow-hidden"
        >			
			<TransitionChild
				as="div"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
				className="w-full h-full absolute  bg-black/50 transition-opacity duration-300"
				onClick={onBackDropClick}
			/>			
			<TransitionChild
				as="div"
				ref={contentRef}
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
				className={`transition-[opacity,transform] duration-300 rounded relative ${
					themeSkin === "default" ? "shadow-lg" : themeSkin
				}`}
				style={{
					maxWidth: modalWidth,
					width: width === "auto" ? "auto" : "100%",
					height: modalHeight,
				}}
			>
				{ isLoading &&
					<div 
						className='absolute top-0 left-0 w-full bg-black z-[80] h-[3px] progress-bar rounded-t'
					>
					</div>
				}
				{/* Button Close */}
				<div className="max-h-[90vh] h-full w-full bg-white dark:bg-base-600 rounded overflow-y-auto custom-scroll">
					{ withHeader &&
						<div className='px-[20px] py-[20px] border-b border-b-gray-300 font-bold'>{title}</div>
					}
					{children}
				</div>
			</TransitionChild>			
        </Transition>
    )

}

export default Modal