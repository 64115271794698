import {
	autoUpdate,
	flip,
	offset,
	shift,
	useFloating,
} from "@floating-ui/react";
import { Popover as PopoverHeadless, Transition } from "@headlessui/react";
import { Fragment } from "react";

import { PopoverProps } from "../../@types/@types.theme";

const Popover = ({
	children,
	button,
	placement,
	spacing = 5,
	fill,
	rounded="sm",
	isShift = true,
	isFlip = true,
} : PopoverProps) => {
	// Rounded
	const panelRounded =
		{
			none: "rounded-none",
			sm: "rounded-sm",
			md: "rounded-md",
			lg: "rounded-lg",
			xl: "rounded-xl",
			"2xl": "rounded-2xl",
			"3xl": "rounded-3xl",
			full: "rounded-full",
		}[rounded] || "rounded";

	const { refs, floatingStyles } = useFloating({
		placement,
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(spacing),
			isFlip ? flip() : null,
			isShift ? shift({ padding: 10 }) : null,
		],
	});
	return (
		<PopoverHeadless className="w-fit relative">
			{({ open }) => (
				<>
					<PopoverHeadless.Button ref={refs.setReference} as="div">
						{button}
					</PopoverHeadless.Button>

					<Transition
						as={Fragment}
						// className="relative z-10"
						enter="transition duration-100 ease-out"
						enterFrom="transform scale-95 opacity-0"
						enterTo="transform scale-100 opacity-100"
						leave="transition duration-75 ease-out"
						leaveFrom="transform scale-100 opacity-100"
						leaveTo="transform scale-95 opacity-0"
					>
						<PopoverHeadless.Panel
							ref={refs.setFloating}
							style={floatingStyles}
							className={`absolute bg-white dark:bg-base-600  ${
								fill ? "w-full" : ""
							} ${panelRounded} shadow-lg overflow-hidden`}
						>
							{children}
						</PopoverHeadless.Panel>
					</Transition>
				</>
			)}
		</PopoverHeadless>
	);
};

export default Popover;
