import { ThemeContext } from "../../context/ThemeContext";
import { Fragment, useContext } from "react";
// import { HiOutlineMenu } from "react-icons/hi";
// import {
// 	Avatar,
// 	Badge,
// 	Button,
// 	// ButtonDarkMode,
// 	// ButtonRipple,
// 	Popover,
// 	// Tooltip,
// } from "..";
// import { TbBell } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import Popover from "../Atoms/Popover";
import Badge from "../Atoms/Badge";
import Avatar from "../Atoms/Avatar";
import Button from "../Atoms/Button";
import Cookies from 'universal-cookie';
// import config from "../../config";
import { Icon } from "@iconify/react/dist/iconify.js";
import { contextTypeProps, themeTypeProps } from "../../@types/@types.theme";
import { config } from "../../constants/config";

const Header = ({ setSideOpen }: any) => {
	const { themeSkin, navbarType, title } = useContext(ThemeContext) as themeTypeProps;
	const {showHeader } = useContext(UserContext) as contextTypeProps;
	// const [showHeader] = myState.showHeader

	const navigate = useNavigate();

	const cookies = new Cookies();    
    
    const handleLogOut = () => {
        cookies.remove(config.cookiesName, { path: '/' });		
		navigate('/authentication')
    }

	


	return (
		<Fragment>
			{ showHeader &&
				<header
					className={`bg-base-50/30 dark:bg-neutral-900/10 backdrop-blur-sm h-20 px-6 pt-4 pb-0 top-0 w-full z-30   ${navbarType} invisible md:visible h-0 md:h-20 hidden md:flex md:flex-col`}
				>
					<div
						className={`w-full h-full flex justify-between items-center px-6 bg-white/80 dark:bg-base-600/80 backdrop-blur-sm rounded-md ${
							themeSkin === "default" ? "shadow-lg" : themeSkin
						}`}
					>
						<div className='hidden md:block font-semibold'>{title}</div>
						<div className='lg:hidden flex items-start  w-full'>
							<div
								onClick={() => setSideOpen(true)}
								className="cursor-pointer block"
							>
								{/* <HiOutlineMenu className="text-2xl" /> */} 
								<Icon icon="mingcute:menu-fill" />
							</div>
						</div>

						<div className="flex items-center gap-4">
							<div className="flex items-center">
								
								{/* Dark Mode */}
								{/* <Tooltip tooltip="Dark Mode" delay={500}>
									<ButtonDarkMode />
								</Tooltip> */}

								{/* Notification */}
								
							</div>

							{/* Profile */}
							<Popover
								placement="bottom-end"
								spacing={20}
								button={		
									<div className='cursor-pointer'>
										<Badge size="sm" placement="right-end" color="success">
											<Avatar color="primary">AD</Avatar>
										</Badge>									
									</div>							
								}
							>
								<div className="text-sm min-w-[200px] rounded-lg p-2">
									<button
										onClick={() => navigate('/admin/change-password')}
										color="base"
										className='border-b border-b-gray-400 w-full py-3 hover:bg-gray-200'
									>
										Change Password
									</button>
								</div>
								<div className="text-sm min-w-[200px] rounded-lg p-2">
									<Button
										onClick={handleLogOut}
										variant="text"
										block
										color="danger"
									>
										Logout
									</Button>
								</div>
							</Popover>
						</div>
					</div>
				</header>		
			}
		</Fragment>
	);
};

export default Header;
