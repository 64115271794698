import { useContext, useEffect, useRef, useState } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { ThemeContext } from "../../context/ThemeContext";
import { themeTypeProps } from "../../@types/@types.theme";
import { useNavigate } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import { Icon } from "@iconify/react/dist/iconify.js";
import ButtonRipple from "../Atoms/ButtonRipple";
import Menu from "./Menu";

const Sidebar = ({ sideOpen, setSideOpen }: any) => {
    const [width] = useWindowSize();
    const { themeColor, colorMode, themeSkin } = useContext(ThemeContext) as themeTypeProps;

    const [openHover, setOpenHover] = useState(false);
    const scrollbarContainer = useRef<any>();
	const [scrolled, setScrolled] = useState(false);
	const navigate = useNavigate();


	// Initialize PerfectScrollbar
	useEffect(() => {
		const ps = new PerfectScrollbar(scrollbarContainer.current, {
			wheelPropagation: true,
			useBothWheelAxes: true,
		});

        if (scrollbarContainer.current) {            
            scrollbarContainer.current.addEventListener("ps-scroll-y", () => {
                if (ps.scrollbarYTop > 0) {
                    setScrolled(true);
                } else {
                    setScrolled(false);
                }
            });
        }

		return () => {
			ps.destroy();
		};
	}, []);

	useEffect(() => {
		if (width && width < 1024) {
			setSideOpen(false);
		}
	}, [width]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
		<>
			{/* Sidebar */}
			<aside
				onMouseEnter={() => width > 1024 && setOpenHover(true)}
				onMouseLeave={() => setOpenHover(false)}
				className={`fixed lg:left-0 z-50 h-full flex flex-col  bg-white dark:bg-base-600 transition-[width,left] duration-500 tracking-wide ${
					sideOpen || openHover ? "w-64 -left-0" : "w-20 -left-96"
				} ${themeSkin === "default" ? "shadow-lg" : themeSkin}`}
			>
				{/* Logo */}
				<div className="flex justify-between items-center w-full h-16 px-3 mx-auto">
					<div
						style={{
							color: themeColor,
						}}
						className={`cursor-pointer font-bold ${sideOpen || openHover ? "" : "w-20"}`}
						onClick={() => navigate("/")}
					>
						{ sideOpen || openHover ? 'MUNAS 2024' : 'PM' }
					</div>
					<button
						className={`text-xl hidden lg:block ${
							!sideOpen && !openHover ? "lg:hidden" : ""
						}`}
						onClick={() => {
							setSideOpen(!sideOpen);
						}}
					>
						{sideOpen ? (
							<Icon icon="lucide:circle-dot" />

						) : (
							<Icon icon="lucide:circle" />
						)}
					</button>
					<ButtonRipple
						color={colorMode === "light" ? "#00000030" : "#ffffff30"}
						className="block lg:hidden p-2 rounded-full transition-[background] hover:bg-neutral-200 dark:hover:bg-base-400"
						onClick={() => setSideOpen(false)}
					>
						<Icon icon="humbleicons:times" />
					</ButtonRipple>
				</div>

				{/* shadow */}
				<div
					className={`bg-gradient-to-b from-white to-transparent dark:bg-gradient-to-b dark:from-base-600 dark:to-transparent h-10 w-full absolute top-16 z-10 transition-opacity duration-200 pointer-events-none ${
						scrolled ? "opacity-100" : "opacity-0"
					}`}
				></div>

				{/* Menu */}
				<div
					ref={scrollbarContainer}
					className="relative flex-1 overflow-hidden group/rail"
				>
					<Menu sideOpen={sideOpen} openHover={openHover} />
				</div>
			</aside>

			{/* Backdrop */}
			<div
				onClick={() => setSideOpen(false)}
				className={`fixed inset-0 bg-black/30 z-40 transition-opacity duration-500 ${
					sideOpen
						? "opacity-100 pointer-events-auto lg:opacity-0 lg:pointer-events-none"
						: "opacity-0 pointer-events-none"
				}`}
			></div>
		</>
    )
}
export default Sidebar;