import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../hooks/globalFunction";
import { config } from "../../../constants/config";
import api from "../../../constants/api";
import { pageObj } from "../../../hooks/pageObj";
import { UserContext } from "../../../context/UserContext";
import { contextTypeProps } from "../../../@types/@types.theme";

interface listDataProps {
    [key: string] : any;
}

interface useTenantProps { 
    query_type? : string
}

export function useTenant({
    query_type='',
}: useTenantProps){
    const { isApprove, isActiveKontak } = useContext(UserContext) as contextTypeProps;
    const { isLoading, setIsLoading, showLoading, closeLoading,
        setPageValue, pageValue, alertValue, setAlertValue,
        modalValue, handleCloseModal, setModalValue, handleCloseAlert
    } = useGlobalFunction();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [listData, setListData] = useState<listDataProps>({isLoading : true, data : []})
    const [view, setView] = useState<string>('');
    const [formData, setFormData] = useState<{[key: string] : any}>({
        id : {
            name : 'id',
            value : '',
            label : 'App Name',
            type : 'text',
            require : false,
            showError : false,
            readOnly : true
        },
        nama : {
            name : 'nama',
            value : '',
            label : 'App Name',
            type : 'text',
            require : true,
            showError : false,
            readOnly : false
        },
        public_key : {
            name : 'public_key',
            value : '',
            label : 'Public Key',
            type : 'textarea',
            require : true,
            showError : false,
            readOnly : false
        },
        logout_url : {
            name : 'logout_url',
            value : '',
            label : 'Logout Url',
            type : 'text',
            require : false,
            showError : false,
            readOnly : false
        },
        client_key : {
            name : 'client_key',
            value : '',
            label : 'Client Key',
            type : 'text',
            require : false,
            showError : false,
            readOnly : true
        },
        client_id : {
            name : 'client_id',
            value : '',
            label : 'Client ID',
            type : 'text',
            require : false,
            showError : false,
            readOnly : true
        },
    })
    

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading()
        try{
            const params:any = {
                offset : offset,
                limit : limit,                
            }
            if (query_type){
                params['query_type'] = query_type;
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.tenantTenant}`, {params: params}).then(res => res.data);
            if (res){
                setListData((listData:any) => ({...listData, isLoading: false, data: res.results}))
                setPageValue((pageValue:any) => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
                setView('list')
            }else{
                setListData(listData => ({...listData, isLoading: false}))
            }
            closeLoading()
        }catch(error: any){
            setListData(listData => ({...listData, isLoading: false}))
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[query_type])
    
    useEffect(() => {        
        fetchData();
    },[fetchData])

    const handleCreate = () => {
        setView('create');
        setIsEdit(false);
        setFormData((formData: any) => ({...formData,
            id : {...formData.id, value : ''},
            nama : {...formData.nama, value : ''},
            public_key : {...formData.public_key, value : ''},
            logout_url : {...formData.logout_url, value : ''},
            client_key : {...formData.client_key, value : ''},
            client_id : {...formData.client_id, value : ''},
        }))
    }

    const handleCancel = () => {
        setView('list');
        fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
        setIsEdit(false);        
    }

    const handleSubmit = async() => {
        showLoading()
        try{
            let obj = {...formData}
            let countError = 0;
            let tmp :any = {}
            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    post.showError= true;
                    countError++;
                }else{
                    tmp[index] = post.value
                }
                    return true;
            })
            
            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Error',
                    subText: 'Lengkapi data',
                    color: 'danger'
                }))
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            let res : any;
            if (isEdit){
                res = await api.put(`${config.endPoint.tenant}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.tenant}`, newForm).then(res => res.data);
            }
            if (res){                
                setFormData((formData: any) => ({...formData,
                    id : {...formData.id, value : res.results.id},
                    nama : {...formData.nama, value : res.results.nama},
                    public_key : {...formData.public_key, value : res.results.public_key},
                    logout_url : {...formData.logout_url, value : res.results.logout_url},
                    client_key : {...formData.client_key, value : res.results.client_key},
                    client_id : {...formData.client_id, value : res.results.client_id},
                }))
                setAlertValue((alertValue: any) => ({...alertValue,
                    show: true,
                    text : 'successfully',
                    subText: isEdit ? 'Tenant Updated' : 'Tenant Created',
                    color: 'success'
                }))
                setIsEdit(true);
            }
        }catch(error:any){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleChangeSearch = (e:any) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
    }
    
    const handleKeyDownSearch = (e:any) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }
    
    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handlePaginate = (page:number) => {
        let myOffset = (page * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    const handleEdit = (post: any) => {
        setView('create');
        setIsEdit(true);
        setFormData((formData: any) => ({...formData,
            id : {...formData.nama, value : post.id},
            nama : {...formData.nama, value : post.nama},
            public_key : {...formData.public_key, value : post.public_key},
            logout_url : {...formData.logout_url, value : post.logout_url || ''},
            
        }))
    }

    const handleDelete = (params:any) => {
        setModalValue((modalValue: any) => ({...modalValue,
            show: true,
            text: `Apakah anda yakin akan menghapus daftar pemilih ${params.nama} ?`,
            id : params.id,
            title : 'Konfirmasi',
        }))
    }
    
    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.tenant}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Delete Success',
                    subText: 'Data berhasil di delete',
                    color: 'success'
                }))
    
                setTimeout(() => {
                    handleCloseAlert();
                }, config.timeOutValue)
            }
            handleCloseModal()
        }catch(error: any){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to delete data'
    
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
    
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    return {
        isLoading, setIsLoading, listData, setListData,
        pageValue, setPageValue, alertValue, setAlertValue,
        view, setView, handleCreate, handleSubmit, handleCancel,
        handleChangeSearch, handleKeyDownSearch, handleSearch,
        handlePaginate, formData, setFormData, isApprove,
        isActiveKontak, handleEdit, handleSubmitDelete, handleDelete, modalValue, setModalValue, handleCloseModal,
        isEdit, handleCloseAlert
    }
}