import { useRef } from "react";

import InputLoginComp from "../../../components/Atoms/InputLoginComp";
import { Icon } from "@iconify/react";
import { useConfirmEmail } from "./confirmEmailFunction";

const ConfirmEmailTenantPage = () => {
    const { formData, handleChange, handleBlur, handleShowPassword, handleSubmit, isLoading, view, alertValue } = useConfirmEmail();
    const ref = useRef<any>(null);

    return(
        <>            
            { view === 'success' ?
                <div className='w-full h-screen bg-gradient-to-r from-blue-400 to to-blue-800 font-poppins flex flex-row items-center justify-center relative'>
                        <div className="wrapper animated-signin   w-[400px] relative h-[70%] max-h-[500px]  flex flex-col items-center justify-center" ref={ref}>
                        <div className="bg-white w-[100%] h-[100%] sign-up absolute flex flex-col py-[30px] items-center shadow-xl rounded-md px-[30px]">
                            <div className='font-bold text-[24px] text-gray-600'>Create Password</div>
                            <InputLoginComp
                                labelName="Nama"
                                rooClassName='mt-[55px]'
                                value={formData.nama.value}
                                name={formData.nama.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                readOnly={formData.nama.readOnly || false}
                            />
                            <InputLoginComp
                                labelName={formData.email.label}
                                rooClassName='mt-[20px]'
                                value={formData.email.value}
                                name={formData.email.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                readOnly={formData.email.readOnly || false}
                            />
                            <InputLoginComp
                                labelName={formData.password.label}
                                rooClassName='mt-[20px]'
                                value={formData.password.value}
                                name={formData.password.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                tipe={formData.password.type}
                                handleShowPassword={() => handleShowPassword(formData.password.name)}
                                showPassword={formData.password.showPassword}
                            />
                            <InputLoginComp
                                labelName={formData.confirm_password.label}
                                rooClassName='mt-[20px]'
                                value={formData.confirm_password.value}
                                name={formData.confirm_password.name}
                                onChange={handleChange}
                                onBlur={handleBlur}   
                                tipe={formData.confirm_password.type} 
                                handleShowPassword={() => handleShowPassword(formData.confirm_password.name)}
                                showPassword={formData.confirm_password.showPassword}
                            />
                            <button className={`flex flex-row gap-2 justify-center submit-btn w-full bg-gradient-to-r items-center from-blue-400 to to-blue-800 mt-12 py-2 text-white rounded-lg shadow-lg`}
                                onClick={handleSubmit}
                                disabled={isLoading}                 
                            >
                                { isLoading &&
                                    <Icon icon="line-md:loading-alt-loop" />
                                }
                                Submit
                            </button>
                        </div>
                    </div>
                </div>    
                :
                <div className='w-full h-screen bg-gradient-to-r from-blue-400 to to-blue-800 font-poppins flex flex-row items-center justify-center relative'>
                    <div className='w-[400px] h-[400px] bg-white rounded-md shadow-xl p-6 flex flex-col justify-center items-center'>
                        <div className='w-[150px] h-[150px] bg-red-100 text-red-500 rounded-full flex flex-row justify-center items-center'>
                            <Icon icon="tabler:error-404" fontSize={104}/>
                        </div>
                        <div className='mt-5'>{alertValue.subText}</div>
                    </div>
                </div>
            }
        </>
    )
}
export default ConfirmEmailTenantPage;