import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../hooks/globalFunction";
import { config } from "../../../constants/config";
import api from "../../../constants/api";
import { pageObj } from "../../../hooks/pageObj";


export function useKontak(){
    const { isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        modalValue, setModalValue, handleCloseModal, pageValue, setPageValue, closeLoading, showLoading
    } = useGlobalFunction();
    const [listData, setListData] = useState({isLoading : isLoading, data: []});
    const [view, setView] = useState('list');
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
        nama : {
            name : 'nama',
            type: '',
            label : '',
            value : '',
            required : true,
            showError : false,
            show: true,
        },
    })

    const handleCreate = () => {
        setView('create')
        setIsEdit(false);
    }

    const handleSubmit = async() => {
        try{

        }catch(error: any){

        }
    }

    const handleCancel = () => {
        setView('list')
    }

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading()
        try{
            const params : any = {
                offset : offset,
                limit : limit,
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.kontak}`, {params: params}).then(res => res.data);
            if (res){
                setListData((listData: any) => ({...listData, isLoading: false, data: res.results}))
                setPageValue((pageValue: any) => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }else{
                setListData((listData: any) => ({...listData, isLoading: false}))
            }
            closeLoading()
        }catch(error: any){
            setListData((listData: any) => ({...listData, isLoading: false}))
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue((alertValue: any) => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue((alertValue: any) => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])
    
    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleChangeSearch = (e:any) => {
        const { name, value } = e.target;
        setPageValue((pageValue: any) => ({...pageValue, [name] : value}))
    }
    
    const handleKeyDownSearch = (e: any) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }
    
    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handlePaginate = (page:any) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue((pageValue: any) => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    const handleApprove = (post:any) => {
        setModalValue((modalValue: any) => ({...modalValue,            
            show: true,
            title : 'Konfirmasi',
            text : `Apakah anda yakin akan melakukan persetujan an. ${post.nama} ?`,
            id: post.id
        }))
    }

    const handleSubmitApprove = async() => {
        setIsLoading(true);
        try{
            let tmp = {
                'is_approve' : true
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp))

            const res = await api.put(`${config.endPoint.kontakApprove}${modalValue.id}/`, newForm).then((res: any) => res.data);
            if (res){                
                console.log('res is >>>>', res)
                setAlertValue((alertValue: any) => ({...alertValue,
                    show: true,
                    text : 'successfully',
                    subText: res.message,
                    color: 'success'
                }))
                setTimeout(() => {
                    handleCloseAlert();
                }, config.timeOutValue)
            }
            handleCloseModal();
            setIsLoading(false);
        }catch(error: any){
            setIsLoading(false);
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue((alertValue: any) => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue((alertValue: any) => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    return {
        isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert, 
        modalValue, setModalValue, handleCloseModal, formData, setFormData,
        isEdit, setIsEdit, view, setView, listData, setListData, pageValue, setPageValue, closeLoading, showLoading,
        handleSubmit, handleCreate, handleCancel, handleChangeSearch, handleKeyDownSearch, handleSearch,
        handlePaginate, handleApprove, handleSubmitApprove
    }
}