
import { ThemeContext } from "../../context/ThemeContext";
import { Disclosure, Transition } from "@headlessui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { RxBorderDashed } from "react-icons/rx";
import { TbChevronRight, TbCircle } from "react-icons/tb";
import { NavLink, useLocation,  } from "react-router-dom";
import { contextTypeProps, themeTypeProps } from "../../@types/@types.theme";
// import { useMenu } from "../../constants/menu";
import api from "../../constants/api";
import { config } from "../../constants/config";
import { menu } from "../../constants/menu";
import { UserContext } from "../../context/UserContext";
// import { menu } from "../../constants/menu";

// import Cookies from 'universal-cookie';
// import config from "../../config";

const Menu = ({ sideOpen, openHover }: any) => {
	const {  setIsSuperUser,  isSuperUser, setIsApprove, setIsStaff, setIsActiveKontak, isStaff} = useContext(UserContext) as contextTypeProps;
	const [initNav, setInitNav] = useState<any>([]);
	const [nav, setNav] = useState<any>([]);
	const { pathname } = useLocation();
	// const cookies = new Cookies();
	// const myCookies = cookies.get(config.cookiesName);      
	const [myMenu, setMyMenu] = useState<any>([]);
	const { themeColor } = useContext(ThemeContext) as themeTypeProps;
	const [listPermissions, setListPermissions] = useState<any>([]);
	// const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
	const [readyToRender, setReadyToRender] = useState(false);
	const [readyMenu, setReadyMenu] = useState(false);
	

	const navOpen = (data:any) => {
		setNav({ ...initNav, [data]: !nav[data] });
	};

	const navClose = (data:any) => {
		if (data) {
			setNav({ ...initNav, [data]: true });
		} else {
			setNav(initNav);
		}
	};

	

	
	
	
	const fetchData = useCallback(async() => {		
		try{
			const res = await api.get(`${config.endPoint.userRole}`).then((res: any) => res.data);			
			setListPermissions(res.data);
			setIsSuperUser(res.is_superuser)
			setIsStaff(res.is_staff);
			setIsApprove(res.is_approve);		
			setIsActiveKontak(res.is_active_kontak)	
			setReadyMenu(true);
		}catch(error: any){
			// setIsLoading(false);

		}
		// eslint-disable-next-line 
	},[])

	useEffect(() => {
		fetchData();
	},[fetchData])

	useEffect(() => {
		if (readyMenu){

			if (isSuperUser){
				setMyMenu(menu);
			}
			else{
				let tmp : any = [];
				menu.map((post:any) => {
					if (post.sub.length > 0){
						if (post.name.includes('master') && !isStaff){
							return false
							// console.log('masernya ada >>>>', post)
						}else{
							let tmp_sub_menu : any = [];
							post.sub.map((submenu: any) => {
								if (listPermissions.includes(submenu.permission_name)){
									tmp_sub_menu.push(submenu)
								}
								return true;
							})
							post['sub'] = tmp_sub_menu
							tmp.push(post);
						}
					}else{
						
						if (listPermissions.includes(post.permission_name)){
							tmp.push(post);
						}
					}
					return true;
				})
				setMyMenu(tmp);
			}
			setReadyToRender(true);
		}
		
	},[listPermissions, readyMenu, isSuperUser, isStaff])



	useEffect(() => {
		const initNav = myMenu
			.filter((item:any) => item.sub?.length > 0)
			.reduce((acc:any, item:any) => {
				acc[item.name] = false;
				return acc;
			}, {});

		setNav(initNav);
		setInitNav(initNav);
	}, [myMenu]);

	useEffect(() => {
		if (!openHover) {
			if (!sideOpen) setNav(initNav);
		}
	}, [openHover]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!sideOpen) {
			if (openHover) {
				const menuActive = pathname.split("/")[1];
				setNav({ ...initNav, [menuActive]: true });
			}
		}
	}, [openHover]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="my-3">			
			{readyToRender && myMenu.map((item:any, itemIdx:any) => {
				if (item.label) {
					return (
						<div
							key={itemIdx}
							className={`px-3 mt-6 mb-3 m-3 text-xs text-base-200 whitespace-nowrap h-3 ${
								sideOpen || openHover ? "" : "flex items-center justify-center"
							}`}
						>
							{sideOpen || openHover ? item.label : <RxBorderDashed />} asdfasdf
						</div>
					);
				}
				
				if (item?.sub?.length === 0) {
					return (
						<NavLink onClick={navClose} key={itemIdx} to={item.path}>
							{({ isActive }) => (
								<div className="mb-1 px-3 w-full text-sm">
									<div
										style={{
											background: isActive
												? `linear-gradient(90deg, ${themeColor} 0%, ${themeColor}90 100%)`
												: "",
											boxShadow: isActive
												? `0px 0px 10px 0px ${themeColor}60`
												: "",
										}}
										className={`flex items-center justify-between w-full h-10 px-[18px] py-2 rounded-md overflow-hidden ${
											isActive
												? "text-white"
												: "hover:bg-base-50 dark:hover:bg-base-500"
										}`}
									>
										<span className="flex items-center gap-2">
											<span className="text-xl w-5">{item.icon}</span>
											<span
												className={`${
													sideOpen || openHover ? "opacity-100" : "opacity-0"
												}`}
											>
												{item.title}
											</span>
										</span>
									</div>
								</div>
							)}
						</NavLink>
					);
				}

				if (item?.sub?.length > 0) {					
					return (
						<Disclosure key={itemIdx}>							
							<Disclosure.Button
								onClick={() => navOpen(item.name)}
								className="w-full px-3 mb-1 text-sm"
							>
								<div
									className={`flex items-center justify-between w-full h-10 px-[18px] py-2 rounded-md overflow-hidden ${
										nav[item.name]
											? "bg-base-50 dark:bg-base-500"
											: "hover:bg-base-50 dark:hover:bg-base-500"
									} ${
										pathname.split("/")[1] === item.name
											? "bg-base-50 dark:bg-base-500"
											: ""
									}`}
								>
									<span className="flex items-center gap-2">
										<span className="text-xl w-5">{item.icon}</span>
										<span
											className={`tracking-wide ${
												sideOpen || openHover ? "opacity-100" : "opacity-0"
											}`}
										>
											{item.title}
										</span>
									</span>

									<div
										className={`flex items-center gap-2 transition-[transform,opacity] ${
											nav[item.name] ? "rotate-90" : ""
										} ${sideOpen || openHover ? "opacity-100" : "opacity-0"}`}
									>
										<TbChevronRight />
									</div>
								</div>
							</Disclosure.Button>
							<Transition
								show={nav[item.name]}
								// className="overflow-hidden"
								enter="transition-[max-height] duration-300 ease-in"
								enterFrom="max-h-0"
								enterTo="max-h-screen"
								leave="transition-[max-height] duration-300 ease-out"
								leaveFrom="max-h-screen"
								leaveTo="max-h-0"
							>
								<Disclosure.Panel as="div" className="pb-2">
									{item?.sub?.map((subItem:any, subItemIdx:any) => (
										<div key={subItemIdx} className="mb-1 px-3 w-full text-sm">
											<NavLink
												onClick={() => navClose(item?.name)}
												to={subItem.path}
											>
												{({ isActive }) => (
													<div
														style={{
															background: isActive
																? `linear-gradient(90deg, ${themeColor} 0%, ${themeColor}90 100%)`
																: "",
															boxShadow: isActive
																? `0px 0px 10px 0px ${themeColor}60`
																: "",
														}}
														className={`flex items-center justify-between w-full h-10 px-[18px] py-2 rounded-md overflow-hidden ${
															isActive
																? "text-white"
																: "hover:bg-base-50 dark:hover:bg-base-500"
														}`}
													>
														<span className="flex items-center gap-2">
															<span className="text-[10px] w-[18px]">
																{ subItem.icon ? subItem.icon :  <TbCircle /> }
															</span>
															<span
																className={`${
																	sideOpen || openHover
																		? "opacity-100"
																		: "opacity-0"
																}`}
															>
																{subItem.title}
															</span>
														</span>
													</div>
												)}
											</NavLink>
										</div>
									))}
								</Disclosure.Panel>
							</Transition>
						</Disclosure>
					);
				}

				return null;
			})}
		</div>
	);
};

export default Menu;
