import Search from '../Atoms/Search';
import  { Fragment, useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';

import { Icon } from '@iconify/react/dist/iconify.js';
import Button from './Button';
import { cardProps, themeTypeProps } from '../../@types/@types.theme';
// import { motion } from "framer-motion"



const Card = ({
    // title, 
    children, 
    isSearch, 
    isCreate,
    handleSubmit, 
    handleCancel,
    onChangeSearch= () => {}, 
    handleSearch = () => {}, 
    searchValue='',
    handleCreate,                
    className,
    btnCreateText="Tambah",
    disableIconButtonCreate,
    ChildButton,        
    bodyStyle,
    showCreateButton=true,
    btnSaveText="Simpan",
    btnCancelText="Cancel",
}: cardProps) => {    
   
    const { themeColor } = useContext(ThemeContext) as themeTypeProps
    const bgColor:any = {
        "#7367f0" : `bg-[#7367f0]`,
        "#f43f5e" : `bg-[#f43f5e]`,
        "#3b82f6" : `bg-[#3b82f6]`,
        "#14b8a6" : `bg-[#14b8a6]`,
        "#eab308" : `bg-[#eab308]`,
    }    
    
    return(
        <div className={`shadow-lg bg-white dark:bg-base-600  rounded-md relative ${className}`}>                                   
            <div className='p-6'>
                <div className='flex flex-col md:flex-row w-full justify-between mb-5'>
                    { isSearch && !isCreate &&
                        <div className='w-full md:w-60'>
                            <Search
                                placeholder={"Cari"}
                                onChange={(value:any) => onChangeSearch(value)}
                                handleSearch={handleSearch}
                                value={searchValue}
                            />    
                        </div>
                    }
                    { !isCreate && handleCreate && showCreateButton &&
                        <Fragment>                            
                            <div className="md:flex  justify-end md:justify-end gap-1 items-center">
                                {/* <motion.div                                            
                                    whileHover={{
                                        // scale: 1.2,
                                        rotate: 360,
                                        transition: { duration: 0.5 },
                                    }}
                                    whileTap={{ scale: 0.9 }}
                                > */}
                                    <button  onClick={handleCreate} className={`px-4 py-[10px] leading-3  flex flex-row items-center  ${bgColor[themeColor]} text-white text-[14px] rounded`}>
                                        { !disableIconButtonCreate &&
                                            <Icon icon="ic:round-plus" />
                                        }
                                        &nbsp;<div className='md:visible  md:w-fit'>
                                            { btnCreateText }
                                        </div>
                                    </button>
                                {/* </motion.div> */}
                                { ChildButton && 
                                    <Fragment>
                                        {ChildButton}                                       
                                    </Fragment>
                                }
                            </div>       
                        </Fragment>
                    }

                </div>
                <div style={bodyStyle}>

                    { children }
                </div>
                { isCreate && (handleSubmit || handleCancel) &&
                    <div className='flex flex-row w-full border-t border-t-gray-200 mt-6 pt-4'>
                        { isCreate &&
                            <div className='flex flex-row justify-end w-full gap-2'>
                                { handleSubmit &&
                                    // <motion.div
                                        //     whileHover={{
                                        //         scale: 1.1,                                            
                                        //         transition: { duration: 0.5 },
                                        //     }}
                                        //     whileTap={{ scale: 0.9 }}
                                        // >
                                        <Button size="md" onClick={handleSubmit}  className={`${bgColor[themeColor]} text-white`}>{btnSaveText}</Button>
                                        // </motion.div>
                                }
                                { handleCancel &&
                                    // <motion.div                                       
                                    //     whileHover={{
                                    //         // scale: 1.2,
                                    //         rotate: 360,
                                    //         transition: { duration: 0.5 },
                                    //     }}
                                    //     whileTap={{ scale: 0.9 }}
                                    // >

                                        <Button size="md" onClick={handleCancel} color='base' className='bg-gray-400 text-white'>{btnCancelText}</Button>
                                    // </motion.div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Card