import { Icon } from "@iconify/react";
import ProductPage from "../views/Master/Product/ProductPage";
// import TenantPage from "../views/Master/Tenant/TenantPage";
import GroupPermissions from "../views/Master/GroupPermission/GroupPermissions";
import DefaultGroupPermissionsPage from "../views/Master/DefaultPermissions/DefaultPermissionsPage";
import TenantTenantPage from "../views/Tenant/Tenant/TenantPage";
import KontakPage from "../views/Master/Kontak/Kontak";
import GroupTenantPage from "../views/GroupTenant/GroupTenantPage";





export const menu: any = [
	{
        icon: <Icon icon="mingcute:settings-6-fill" fontSize={18} />,
		path: "/",
		name: "master",
		title: "Master Data",
		element: <ProductPage />,
        sub : [
			{
				icon : <Icon icon="clarity:file-group-line" fontSize={18} />,
				path: '/master/group-permissions',
				name : 'group-permissions',
				title : 'Group Permissions',
				element: <GroupPermissions/>,
				permission_name : 'view_group'
			},
			{
				icon : <Icon icon="clarity:file-group-line" fontSize={18} />,
				path: '/master/default-group',
				name : 'default-group-permissions',
				title : 'Default Group Permissions',
				element: <DefaultGroupPermissionsPage/>,
				permission_name : 'view_group_default'
			},
			{
				icon : <Icon icon="fluent-mdl2:contact-list" fontSize={18} />,
				path: '/master/kontak',
				name : 'kontak',
				title : 'Kontak',
				element: <KontakPage/>,
				permission_name : 'view_kontak'
			},
			// {
			// 	icon : <Icon icon="icon-park-outline:ad-product" fontSize={18} />,
			// 	path: '/master/tenant',
			// 	name : 'tenant',
			// 	title : 'Tenant',
			// 	element: <TenantPage/>,
			// 	permission_name : 'view_tenant'
			// }
		], 
    },
	{
		icon : <Icon icon="icon-park-outline:ad-product" fontSize={18} />,
		path: '/tenant',
		name : 'tenant',
		title : 'Tenant',
		element: <TenantTenantPage/>,
		permission_name : 'view_tenant',
		sub : [],
	},
	{
		icon : <Icon icon="heroicons:rectangle-group-20-solid" fontSize={18} />,
		path: '/group-tenant',
		name : 'grouptenant',
		title : 'Group Tenant',
		element: <GroupTenantPage/>,
		permission_name : 'view_group_tenant',
		sub : [],
	},
]