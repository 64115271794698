import { useState } from "react";
// import { useGlobalFunction } from "../../../../generalFunction";
// import api from "../../../../api";

import { useCookies } from 'react-cookie';
import { useGlobalFunction } from "../../../hooks/globalFunction";
import { config } from "../../../constants/config";
import api from "../../../constants/api";
import { useNavigate } from "react-router-dom";


interface formDataProps {
    [key: string] : any
}

export function useAuthFunction(){
    const { isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,        
    }  = useGlobalFunction();
    const [cookies, setCookie] = useCookies([config.cookiesName]);    
    const [view, setView] = useState<string>('');
    const [viewSignUp, setViewSignUp] = useState<string>('signup')
    const [formForgotPassword, setFormForgotPassword] = useState<formDataProps>({
        email : {
            name : 'email',
            type: 'email',
            label : 'Email',
            value : '',
            require : true,
            showError : false,
            show: true,
            showPassword : false
        },
    })
    const navigate = useNavigate();
    const [formSignUp, setFormSignUp] = useState<formDataProps>({
        nama : {
            name : 'nama',
            type: 'text',
            label : 'Nama',
            value : '',
            require : true,
            showError : false,
            show: true,
        },
        email : {
            name : 'email',
            type: 'email',
            label : 'Email',
            value : '',
            require : true,
            showError : false,
            show: true,
            showPassword : false
        },
    })

    const [formData, setFormData] = useState<formDataProps>({
        username : {
            name : 'username',
            type: 'text',
            label : 'Username',
            value : '',
            require : true,
            showError : false,
            show: true,
        },
        password : {
            name : 'password',
            type: 'password',
            label : 'Password',
            value : '',
            require : true,
            showError : false,
            show: true,
            showPassword : false
        },
    })

    const handleShowPassword = (name:any) => {        
        setFormData((formData:any) => ({
            ...formData,
            [name]: {
                ...formData[name],
                showPassword: !formData[name].showPassword,
                type: !formData[name].showPassword ? 'text' : 'password'
            }
        }))
    }

    

    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setFormData((formData:any) => ({
            ...formData,
            [name]: { ...formData[name], value: value }
        }))
    }

    const handleBlur = (e:any) => {
        const { name, value } = e.target;
        if (formData[name].require && value === ''){
            setFormData((formData:any) => ({
                ...formData,
                [name]: { ...formData[name], showError: true }
            }))
        }else{
            setFormData((formData:any) => ({
                ...formData,
                [name]: { ...formData[name], showError: false }
            }))
        }
    }

    const handleSubmit = async() => {        
        setIsLoading(true);
        try{
            let countError = 0;
            let tmp : any = {}
            let obj = {...formData};            
            
            Object.entries(obj).map(([index, post]) => {
                if (post.require && post?.value === ''){
                    post['showError'] = true;
                    countError++;                    
                }else{
                    tmp[index] = post?.value;
                }
                return true;
            })
                        
            if (countError > 0){
                setIsLoading(false);
                return;
            }
            

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp))

            const res = await api.post(`${config.endPoint.login}`, newForm).then((res:any) => res.data);    
            if (res){                

                let tmpcookie = {
                    'token' : res.results.token, 
                    // 'user_info' : res.results.user_info,
                    'is_superuser' : res.results.is_superuser,
                    // 'development' : res.development,
                    // 'user_perm' : res.user_perm,
                    'is_login' : true
                };
			    setCookie(config.cookiesName, tmpcookie, { path: '/', maxAge: config.expiresSession });                     
                return window.location.href = '/'
            }            
            setIsLoading(false);
        }catch(error:any){
            setIsLoading(false);            
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }

            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, config.timeOutValue)
        }
    }

    const handleKeyDown = (e:any) => {        
        if (e.key === 'Enter'){
            handleSubmit();
        }
    }

    const handleChangeSignUp = (e:any) => {
        const { name, value } = e.target;
        setFormSignUp((formSignUp:any) => ({
            ...formSignUp,
            [name]: { ...formSignUp[name], value: value }
        }))
    }

    const handleBlurSignUp = (e:any) => {
        const { name, value } = e.target;
        if (formSignUp[name].require && value === ''){
            setFormSignUp((formSignUp: any) => ({
                ...formSignUp,
                [name]: { ...formSignUp[name], showError: true }
            }))
        }else{
            setFormSignUp((formSignUp: any) => ({
                ...formSignUp,
                [name]: { ...formSignUp[name], showError: false }
            }))
        }
    }

    const handleSubmitSignUp = async() => {
        setIsLoading(true);
        try{
            let obj = {...formSignUp}
            let countError = 0;
            let tmp : any= {}
            Object.entries(obj).map(([index, post]) : any => {
                if (post.required && post.value === ''){
                    post.showError= true;
                    countError++;
                }else{
                    tmp[index] = post.value
                }
                    return true;
            })
            
            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Error',
                    subText: 'Lengkapi data',
                    color: 'danger'
                }))
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            const res = await api.post(`${config.endPoint.signUp}`, newForm).then(res => res.data);            
            if (res){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Success',
                    subText: 'Data berhasil disimpan',
                    color: 'success'
                }))
                navigate(`/check-email/${tmp['email']}`)
            }
            setIsLoading(true);
            setView('list');
        }catch(error: any){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleChangeForgotPassword = (e:any) => {
        const { name, value } = e.target;
        setFormForgotPassword((formForgotPassword: any) => ({
            ...formForgotPassword,
            [name]: { ...formForgotPassword[name], value: value }
        }))
    }

    const handleBlurForgotPassword = (e:any) => {
        const { name, value } = e.target;
        if (formForgotPassword[name].require && value === ''){
            setFormForgotPassword((formForgotPassword: any) => ({
                ...formForgotPassword,
                [name]: { ...formForgotPassword[name], showError: true }
            }))
        }else{
            setFormForgotPassword((formForgotPassword: any) => ({
                ...formForgotPassword,
                [name]: { ...formForgotPassword[name], showError: false }
            }))
        }
    }

    const handleSubmitForgotPassword = async() => {

    }

    return {
        isLoading, setIsLoading, formData, setFormData, handleShowPassword,
        handleChange, handleBlur, cookies, handleKeyDown, handleSubmit, alertValue,
        handleCloseAlert,
        formSignUp, setFormSignUp, handleChangeSignUp, handleBlurSignUp,
        handleSubmitSignUp, viewSignUp, setViewSignUp,
        formForgotPassword, setFormForgotPassword,
        handleChangeForgotPassword, handleBlurForgotPassword, handleSubmitForgotPassword,
        setAlertValue, setCookie, view
    }
}