import { useCallback, useEffect, useRef, useState } from "react";
import { useGlobalFunction } from "../../../hooks/globalFunction";
import api from "../../../constants/api";
import { config } from "../../../constants/config";
import { useCookies } from 'react-cookie';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";

interface formDataProps {
    [key: string] : any
}

export function useServiceLogin(){
    const { isLoading, setIsLoading, alertValue, setAlertValue,  }  = useGlobalFunction();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLogin, setIsLogin] = useState(false);
    const [cookies, setCookie] = useCookies([config.cookiesName]);    
    const [title, setTitle] = useState<string>('');
    const ref = useRef<any>(null);
    const navigate = useNavigate();
    const [formSignUp, setFormSignUp] = useState<formDataProps>({
        nama : {
            name : 'nama',
            type: 'text',
            label : 'Nama',
            value : '',
            require : true,
            showError : false,
            show: true,
        },
        email : {
            name : 'email',
            type: 'text',
            label : 'Email',
            value : '',
            require : true,
            showError : false,
            show: true,
            showPassword : false
        },
    })
    


    const [formData, setFormData] = useState<{[key: string] : any}>({
        username : {
            name : 'username',
            type: 'text',
            label : 'Username',
            value : '',
            require : true,
            showError : false,
            show: true
        },
        password : {
            name : 'password',
            type: 'password',
            label : 'Password',
            value : '',
            require : true,
            showError : false,
            show: true,
            showPassword : false
        }
    })

    const handleSignUp = (params:any=null) => {        
        // if (params){
        //     setViewSignUp(params);
        // }
        console.log('handle SignUp')
        const content : any = ref.current;
        content.classList.add("animated-signin");
        content.classList.remove("animated-signup");
    }

    const handleSignIn = () => {
        const content :any  = ref.current;
        content.classList.add("animated-signup");
        content.classList.remove("animated-signin");
    }

    const handleChangeDate = (e:any, name: string) => {        
        console.log("name >>>>", e, name)
        setFormSignUp((formSignUp: any) => ({
            ...formSignUp,
            [name]: { ...formSignUp[name], value: e }
        }))        
    }

    const fetchIsLogin = useCallback(async() => {        
        setIsLoading(true);
        try{            
            let tmp = {
                'client_id' : searchParams.get('client_id')
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp))
            const res = await api.post(`${config.endPoint.checkIsLogin}`, newForm).then(res => res.data);
            if (res){
                if (res.is_login && res.is_register){
                    return window.location.href = res.next_url;
                }
                setTitle(res?.group_info?.title)    
                if (!res.is_login){                                                            
                    // const content :any  = ref.current;
                    // content.classList.add("animated-signup");
                    // content.classList.remove("animated-signin");
                    handleSignIn();
                }
                setIsLogin(res?.is_login)

                if (res.group_info && res.group_info.fields){
                    let tmpObj : formDataProps= {...formSignUp};
                    res.group_info.fields.map((post: any) => {
                        if (post.type_value === 'union'){
                            let union_value = post.union_value.split(",")

                            console.log('union_Vlue >>>', union_value)
                            tmpObj[post.key_value] = {
                                name : post.key_value,
                                type: 'select',
                                label : post.label_value,
                                value : union_value.length > 0 ?  union_value[0] : '',
                                require : true,
                                showError : false,
                                show: true,
                                obj : union_value.map((x:any) => ({value : x, label : x}))
                            }
                        }else{
                            tmpObj[post.key_value] = {
                                name : post.key_value,
                                type: post.type_value,
                                label : post.label_value,
                                value : '',
                                require : true,
                                showError : false,
                                show: true,
                            }
                        }
                        return true;
                    })
                    setFormSignUp(tmpObj)
                }
            }
            
            // if (res && res.is_register ){
            //     if (res.next_url){
            //         // window.location.href = res.next_url;
            //     }
            // }else{

            // }
            setIsLoading(false);
        }catch(error: any){
            setIsLoading(false);
            const { response } = error;
            setIsLogin(false);
            console.log('response is >>>>', response);
        }
        // eslint-disable-next-line 
    },[])


    
    useEffect(() => {
        fetchIsLogin();
    },[fetchIsLogin])

    const handleShowPassword = (name:any) => {        
        setFormData((formData:any) => ({
            ...formData,
            [name]: {
                ...formData[name],
                showPassword: !formData[name].showPassword,
                type: !formData[name].showPassword ? 'text' : 'password'
            }
        }))
    }

    

    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setFormData((formData:any) => ({
            ...formData,
            [name]: { ...formData[name], value: value }
        }))
    }

    const handleBlur = (e:any) => {
        const { name, value } = e.target;
        if (formData[name].require && value === ''){
            setFormData((formData:any) => ({
                ...formData,
                [name]: { ...formData[name], showError: true }
            }))
        }else{
            setFormData((formData:any) => ({
                ...formData,
                [name]: { ...formData[name], showError: false }
            }))
        }
    }

    const handleSubmit = async() => {        
        setIsLoading(true);
        try{
            let countError = 0;
            let tmp : any = {}
            let obj = {...formData};            
            
            Object.entries(obj).map(([index, post]) => {
                if (post.require && post?.value === ''){
                    post['showError'] = true;
                    countError++;                    
                }else{
                    tmp[index] = post?.value;
                }
                return true;
            })
                        
            if (countError > 0){
                setIsLoading(false);
                return;
            }
            
            tmp['client_id'] = searchParams.get('client_id')
            

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp))

            const res = await api.post(`${config.endPoint.loginTenant}`, newForm).then((res:any) => res.data);    
            if (res){                                
                let tmpcookie = {
                    'token' : res.results.token, 
                    // 'user_info' : res.data.user_info,
                    'is_superuser' : res.results.is_superuser,
                    // 'development' : res.development,
                    // 'user_perm' : res.user_perm,
                    'is_login' : true
                };
			    setCookie(config.cookiesName, tmpcookie, { path: '/', maxAge: config.expiresSession });                
                window.location.href = res.results.next_url;
            }            
            setIsLoading(false);
        }catch(error:any){
            setIsLoading(false);            
            let msg = 'Kami sedang mengalami gangguan';
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }

            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}));
            }, config.timeOutValue)
        }
    }

    const handleChangeSignUp = (e:any) => {
        const { name, value } = e.target;
        setFormSignUp((formSignUp:any) => ({
            ...formSignUp,
            [name]: { ...formSignUp[name], value: value }
        }))
    }

    const handleBlurSignUp = (e:any) => {
        const { name, value } = e.target;
        if (formSignUp[name].require && value === ''){
            setFormSignUp((formSignUp: any) => ({
                ...formSignUp,
                [name]: { ...formSignUp[name], showError: true }
            }))
        }else{
            setFormSignUp((formSignUp: any) => ({
                ...formSignUp,
                [name]: { ...formSignUp[name], showError: false }
            }))
        }
    }

    const handleSubmitSignUp = async() => {
        try{
            let obj = {...formSignUp}
            let countError = 0;
            let tmp : any= {}
            Object.entries(obj).map(([index, post]) : any => {
                if (post.required && post.value === ''){                    
                    post.showError= true;
                    countError++;
                }else{
                    if (post.type === 'date'){
                        console.log('date from submit >>>>', tmp[index])
                        tmp[index] = moment(post.value).format('YYYY-MM-DD')
                    }else{
                        tmp[index] = post.value
                    }
                }
                    return true;
            })
            
            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Error',
                    subText: 'Lengkapi data',
                    color: 'danger'
                }))
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }

            tmp['client_id'] = searchParams.get('client_id')

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            const res = await api.post(`${config.endPoint.signUpTenant}`, newForm).then(res => res.data);
            if (res){
                navigate(`/check-email/${tmp['email']}`)
                            
            }
        }catch(error: any){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }


    return {
        isLoading, setIsLoading, cookies, handleBlur, handleChange, formData,
        handleSubmit, handleShowPassword, setSearchParams, alertValue, useParams, title, ref,
        formSignUp, setFormSignUp, handleChangeSignUp, handleBlurSignUp, handleSubmitSignUp,
        handleSignIn, handleSignUp,  isLogin, handleChangeDate

    }
}