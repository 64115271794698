import React, { useReducer, useState } from "react";
import { contextTypeProps,  pageStateProps } from "../@types/@types.theme";
import TenantTenantPage from "../views/Tenant/Tenant/TenantPage";


// interface pageStateProps {
//     page: number;
//     search: string;
//     offset: number;
//     obj: Array<number>;
//     lastPage: number
// }

// type contextTypeProps = {
//     pageState : pageStateProps,
//     pageDispatch : React.Dispatch<any>
// }


// const UserContext = React.createContext([{}, () => { }]);
const UserContext = React.createContext<contextTypeProps | null>(null);

const UserProvider:  React.FC<{children: React.ReactNode}> = ({children}) => {
    const [pageState, pageDispatch] = useState<pageStateProps>({
        page : 1,
		search : '',
		offset : 0,
		obj : [],        
        lastPage : 1,     
    })
    const [showHeader, setShowHeader] = useState(true);
    const [myLoading, setMyLoading] = useState(false);
    const [isApprove, setIsApprove] = useState(false);
    const [isStaff, setIsStaff] = useState(false);
    const [isSuperUser, setIsSuperUser] = useState(false);
    const [isActiveKontak, setIsActiveKontak] = useState(false);

    const modalReducer = (state: any, action: any) => {
        switch (action.type) {
            case "OPEN":
                return {
                    isOpen: true,
                    text: action.text,
                    size: action.size,
                    content: action.content,
                    title: action.title,
                    onClose: action.onClose,
                };
            case "TENANT":
                return {
                    isOpen: true,
                    text: action.text,
                    size: action.size,
                    content: <TenantTenantPage 
                            onClickRow={action.onClick}
                            query_type={action.query_type}
                        />,
                    title: action.title,
                    onClose: action.onClose,
                };
            case "OTHERS":
                return {
                    isOpen: true,
                    title: action.title,
                    content: action.content,
                    onClose: action.onClose,
                    size: action.size,
                    id: action.id,
                };
            case "CLOSE":
                return {
                    isOpen: false,
                    text: "",
                    size: null,
                    content: null,
                    title: "",
                };
            default:
                return {
                    isOpen: false,
                    text: "",
                    size: "lg",
                    content: null,
                    title: "",
                    onClose: null,
                };
        }
    };

    const [modalState, modalDispatch] = useReducer(modalReducer, {
        isOpen: false,
        text: "",
        size: "lg",
        content: null,
        title: "",
        onClose: null,
      });

    return (
        <UserContext.Provider value={{
            pageState, pageDispatch, 
            showHeader, setShowHeader, myLoading, setMyLoading,
            isApprove, setIsApprove, setIsStaff, isStaff, setIsSuperUser, isSuperUser,
            isActiveKontak, setIsActiveKontak, modalState, modalDispatch
        }}>
            {children}
        </UserContext.Provider>
    )

}

export { UserContext, UserProvider };