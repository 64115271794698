import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import AuthenticationPage from '../views/Authentication/Authentication/AuthenticationPage';
// import { menu } from '../constants/menu';
import ServiceLogin from '../views/ServiceLogin/ServiceLogin/ServiceLogin';
import ConfirmEmailPage from '../views/Authentication/ConfirmEmail/ConfirmEmail';
import SuccessPage from '../views/Authentication/SuccessPage/SuccessPage';
import PrivateRoute from '../components/Layout/PrivateRoute';
import { menu } from '../constants/menu';
import CheckYourEmailPage from '../views/ServiceLogin/CheckYourEmail/CheckYourEmailPage';
import ConfirmEmailTenantPage from '../views/ServiceLogin/ConfirmEmail/ConfirmEmailPage';


// console.log('menu is >>>>', menu())

const Router = () => {    
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/authentication" element={<AuthenticationPage />}/>
                <Route path="/check-email/:id" element={<CheckYourEmailPage />}/>
                <Route path="/confirm-email/:id" element={<ConfirmEmailPage />}/>
                <Route path="/service-login/confirm-email"  element={<ConfirmEmailTenantPage />}/>
                <Route path="/create-password-success" element={<SuccessPage />}/>
                <Route path="/ServiceLogin" element={<ServiceLogin />}/>
                

                <Route path="/" element={<PrivateRoute />}>
                    <Route path="/" element={<Layout/>} >
                        { menu.map((item:any, index:any) => {
                            if (item.sub?.length > 0){
                                return item.sub.map((sub:any, index:any) => {
                                    return(
                                        <Route key={index} path={sub?.path} element={sub?.element} />
                                    )
                                })
                            } else {
                                return (
                                    <Route key={index} path={item.path} element={item.element} />
                                )
                            }
                        })}
                    </Route>
                </Route>

                
            </Routes>
        </BrowserRouter>
    )
}

export default Router;