
import { themeTypeProps } from '../@types/@types.theme';
import { themeColors } from '../constants/theme';
import React, { createContext,   useEffect,   useState } from 'react';


// type contextTypeProps = {
// 	themeColor: string;
// 	setThemeColor: React.Dispatch<React.SetStateAction<string>>;
// 	// isProduction: boolean;
// 	// setIsProduction: React.Dispatch<React.SetStateAction<boolean>>;
// 	// title: string;
// 	// setTitle: React.Dispatch<React.SetStateAction<string>>;
// 	// saldo: number;
// 	// setSaldo: React.Dispatch<React.SetStateAction<number>>;
// 	// isScroll: boolean;
// 	// setIsScroll: React.Dispatch<React.SetStateAction<boolean>>;
// 	themeSkin: string;
// 	setThemeSkin: React.Dispatch<React.SetStateAction<string>>;
// 	colorMode: string;
// 	setColorMode: React.Dispatch<React.SetStateAction<string>>;
// 	// setAuth: React.Dispatch<React.SetStateAction<boolean>>;
// }

const ThemeContext = createContext<themeTypeProps | null>(null);


const ThemeProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
	const themecolor = localStorage.getItem("theme-color");
	const [themeColor, setThemeColor] = useState(themecolor || themeColors[0]);
	const colormode = localStorage.getItem("theme-color-mode");
	const [colorMode, setColorMode] = useState(colormode || "light");	
	const [title, setTitle] = useState('');
	
	const [isScroll, setIsScroll] = useState(false);

	useEffect(() => {
		localStorage.setItem("theme-color", themeColor.toString());
	}, [themeColor]);

	// // Theme Skin
	const themeskin = localStorage.getItem("theme-skin");
	const [themeSkin, setThemeSkin] = useState<string>(themeskin || "default");
	// useEffect(() => {
	// 	localStorage.setItem("theme-skin", themeSkin.toString());
	// }, [themeSkin]);

	// // Color Mode
	// const colormode = localStorage.getItem("theme-color-mode");
	// const [colorMode, setColorMode] = useState(colormode || "light");
	// useEffect(() => {
	// 	const className = "dark";
	// 	const bodyClassList = document.body.classList;

	// 	colorMode === "dark"
	// 		? bodyClassList.add(className)
	// 		: bodyClassList.remove(className);

	// 	localStorage.setItem("theme-color-mode", colorMode.toString());
	// }, [colorMode]);

	// // Navbar Type
	const navbartype = localStorage.getItem("theme-navbar-type");
	const [navbarType, setNavbarType] = useState(navbartype || "sticky");
	// useEffect(() => {
	// 	localStorage.setItem("theme-navbar-type", navbarType.toString());
	// }, [navbarType]);

	// // Footer Type
	// const footertype = localStorage.getItem("theme-footer-type");
	// const [footerType, setFooterType] = useState(footertype || "sticky");
	// useEffect(() => {
	// 	localStorage.setItem("theme-footer-type", footerType.toString());
	// }, [footerType]);

	

	return (
		<ThemeContext.Provider
			value={{
				themeColor,
				setThemeColor,
				colorMode,
				setColorMode,
				title,
				setTitle,
				themeSkin,
				setThemeSkin,
				isScroll, 
				setIsScroll,
				navbarType,
				setNavbarType,
				// isProduction, 
				// footerType,
				// setFooterType,
				// setIsProduction,
				// saldo,
				// setSaldo,
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
}

export { ThemeContext, ThemeProvider };