import { Icon } from "@iconify/react";
import Card from "../../../components/Atoms/Card"
import PagePagination from "../../../components/Atoms/PagePagination";
import Tables from "../../../components/Atoms/Tables";
import Tooltip from "../../../components/Atoms/Tooltip";
import { useKontak } from "./kontakFunction"
import Modal from "../../../components/Atoms/Modal";
import Button from "../../../components/Atoms/Button";


const KontakPage = () => {
    const { pageValue, view, handleCreate, handleSubmit, handleCancel, handleChangeSearch, handleSearch,
        listData, handlePaginate, modalValue, setModalValue, handleCloseModal, handleApprove,
        handleSubmitApprove
    } = useKontak();
    
    return (
        <>
            <Modal
                show={modalValue.show}
                setShow={() => {
                    setModalValue(modalValue => ({...modalValue, show: false}))
                }}
                width='xl'
                persistent={false}
                btnClose={false}
            >
                <div className='py-[20px] px-[30px] border-b border-b-gray-200 font-bold'>
                    {modalValue.title}
                </div>
                <div className='py-[50px] px-[30px]'>
                    { modalValue.text }
                </div>
                <div className='py-[20px] px-[30px] border-t border-t-gray-200 flex flex-row justify-end gap-2'>
                    <Button color='danger' size='md' onClick={handleSubmitApprove}>Approve</Button>
                    <Button color='base' size='md' onClick={handleCloseModal}>Cancel</Button>
                </div>
            </Modal>
            <Card
                isCreate={view === 'create'}
                isSearch={view === 'list'}
                searchValue={pageValue.search}    
                showCreateButton={true}        
                handleCreate={handleCreate}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
                btnCreateText="Tambah Kontak"
            >
                { view === 'list' &&
                    <>
                        <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Nama</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Email</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>State</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Action</Tables.Header>
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                { listData?.data?.length === 0 ?
                                    <Tables.Row>
                                        <Tables.Data colspan={4} className='text-center'>Belum ada data</Tables.Data>
                                    </Tables.Row>
                                    :
                                    <>
                                        {listData?.data?.map((post: any, index: number) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data className='text-right align-top'>{pageValue.offset + index + 1}</Tables.Data>
                                                    <Tables.Data className='align-top'>{post.nama}</Tables.Data>
                                                    <Tables.Data className='align-top'>{post.email}</Tables.Data>
                                                    <Tables.Data className='align-top'>
                                                        { post.is_tenant ? 
                                                            <div className='w-fit'>
                                                                <div className={`${post.is_approve ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500'} px-4 py-1  rounded-full`}>
                                                                    { post.is_approve ? 'Approved' : 'Waiting Approval'}
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                    </Tables.Data>
                                                    <Tables.Data className='align-top'>
                                                        <div className='w-fit'>
                                                            { post.is_tenant &&
                                                                <Tooltip tooltip="Approve">
                                                                    <button disabled={post.is_approve} onClick={() => handleApprove(post)} className={`${post.is_approve && 'opacity-[0.5]'} bg-blue-500 text-white p-1 text-[12px] rounded-md`}>
                                                                        <Icon icon="jam:hammer-f" />
                                                                    </button>
                                                                </Tooltip>
                                                            }                                                            
                                                        </div>
                                                    </Tables.Data>
                                                </Tables.Row>
                                            )
                                        })}
                                    </>
                                }
                            </Tables.Body>
                        </Tables>
                        <div className='mt-5'>
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePaginate}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </div>
                    </>
                }
            </Card>
        </>
    )
}
export default KontakPage