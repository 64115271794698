import AlertComp from "../../../components/Atoms/AlertComp";
import Card from "../../../components/Atoms/Card";
import PagePagination from "../../../components/Atoms/PagePagination";
import Tables from "../../../components/Atoms/Tables";
import FormTextSingleComp from "../../../components/forms/FormTextSingleComp";
import { useDefaultPermissions } from "./defaultPermissionFunction";

const DefaultGroupPermissionsPage = () => {
    const { listData, pageValue, handlePaginate, handleChange,
        alertValue, handleCloseAlert
     } = useDefaultPermissions();
    return(
        <>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            <Card
                // title='Master Kandidat Ketua Umum'
                isCreate={false}
                isSearch={false}
                searchValue={''}
                // handleCreate={handleCreate}
                // handleSubmit={handleSubmit}
                // handleCancel={handleCancel}
                // onChangeSearch={handleChangeSearch}
                // handleSearch={handleSearch}
            >                            
                <Tables className='border-separate rounded-sm'>
                    <Tables.Head className='bg-[#efeeee]'>
                        <tr>
                            <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Default Group For</Tables.Header>
                            <Tables.Header className='font-bold text-[13px] py-[10px]'>Group</Tables.Header>
                        </tr>
                    </Tables.Head>
                    <Tables.Body>
                        { listData?.data?.length === 0 ?
                            <Tables.Row>
                                <Tables.Data colspan={4} className='text-center'>Belum ada data</Tables.Data>
                            </Tables.Row>
                            :
                            <>
                                {listData?.data?.map((post:any, index:number) => {
                                    return (
                                        <Tables.Row key={index}>
                                            <Tables.Data className='text-right align-top'>{pageValue.offset + index + 1}</Tables.Data>
                                            <Tables.Data className='align-top'>{post.kode_display}</Tables.Data>
                                            <Tables.Data className='align-top'>
                                                <FormTextSingleComp
                                                    {...post.formGroup}
                                                    onChange={(e) => handleChange(e, post)}
                                                    hideLabel={true}
                                                />
                                            </Tables.Data>
                                        </Tables.Row>
                                    )
                                })}
                            </>
                        }
                    </Tables.Body>
                </Tables>
                <div className='mt-5'>
                    <PagePagination
                        data={pageValue.obj}
                        handlePagination={handlePaginate}
                        page={pageValue.page}
                        lastPage={pageValue.lastPage}
                    />
                </div>
            </Card>
        </>
    )
}
export default DefaultGroupPermissionsPage;