type inputProps = {
    type : any;
    placeholder : string; 
    id: string ;
    onChange: () => void;
    onBlur : () => void; 
    value : any;
    className : string; 
    name : string; 
    onKeyDown :  () => void;
    pattern :  string
    textareaRows :  number
    disabled :  boolean
    ref : any
}

const Input = ({
    type,
    placeholder,
    id,
    onChange,
    onBlur,
    value,
    className,
    name,
    onKeyDown,
    pattern,
    textareaRows,
    disabled,
    ref,    
} : inputProps) => {    
    return(
        <>
            { type === 'textarea' ?
                <textarea 
                    className={`text-sm appearance-none 
                        block w-full
                        text-gray-700
                        border-gray-300 
                        rounded py-3 
                        px-2
                        border 
                        leading-tight 
                        focus:outline-1 
                        focus:outline-none 
                        focus:outline-blue-400 
                        ${disabled ? 'bg-gray-200' : 'bg-white'}
                        focus:border-none ${className}`
                    }
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    rows={textareaRows ? textareaRows : 3}
                    onKeyDown={onKeyDown}
                    disabled={disabled}
                    ref={ref}
                ></textarea>
                :
                <input 
                    className={`text-sm appearance-none 
                        block w-full
                        text-gray-700
                        border-gray-300 
                        rounded py-3 
                        px-2
                        border 
                        leading-tight 
                        focus:outline-1 
                        focus:outline-none 
                        focus:outline-blue-400 
                        ${disabled ? 'bg-gray-200' : 'bg-white'}
                        focus:border-none ${className}`
                    }
                    onKeyDown={onKeyDown}
                    pattern={pattern}
                    id={id}
                    type={type} 
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    disabled={disabled}
                    ref={ref}
                />
            }
        </>
    )
}

export default Input;