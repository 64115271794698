import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../hooks/globalFunction";
import { config } from "../../../constants/config";
import api from "../../../constants/api";
import { pageObj } from "../../../hooks/pageObj";




export function useGroupPermissions(){
    const { isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        modalValue, setModalValue, handleCloseModal, showLoading, closeLoading,
        pageValue, setPageValue, catchFetching
    } = useGlobalFunction();
    const [listData, setListData] = useState({isLoading : isLoading, data: []});
    const [view, setView] = useState('list');
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            type: 'text',
            label : 'ID',
            value : '',
            required : true,
            showError : false,
            show: true,
        },
        nama : {
            name : 'nama',
            type: 'text',
            label : 'Nama Group',
            value : '',
            required : true,
            showError : false,
            show: true,
            flexType : 'row',
        },
    })
    const [listAvailablePermissions, setListAvailablePermissions] = useState({data : [], isLoading: false, ori : []});    
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [selectedGranted, setSelectedGranted] = useState([]);
    const [listGranted, setListGranted] = useState({isLoading : false, data:  [], ori : []});

    const fetchData = useCallback(async (q = '', offset = 0, limit = config.itemPerPage) => {
        showLoading()
        try {
            let params :any = {
                offset: offset,
                limit: limit,
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.permissionGroup}`, { params: params }).then(res => res.data);
            if (res) {
                setListData((listData: any) => ({ ...listData, isLoading: false, data: res.results }))
                setPageValue((pageValue: any) => ({
                    ...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            } else {
                setListData((listData: any) => ({ ...listData, isLoading: false }))
            }
            closeLoading()
        } catch (error: any) {
            setListData(listData => ({ ...listData, isLoading: false }))
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message) {
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({
                ...alertValue,
                show: true,
                text: 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({ ...alertValue, show: false }))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    }, [])

    const fetchPermissions = useCallback(async(q='', offset=0, limit: any=1000) => {
        showLoading()
        try{
            const params: any = {
                offset : offset,
                limit : limit,
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.permissions}`, {params: params}).then(res => res.data);
            if (res){
                setListAvailablePermissions(listAvailablePermissions => ({...listAvailablePermissions, isLoading: false, data: res.results, ori: res.results}))
                setPageValue((pageValue: any) => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }else{
                setListAvailablePermissions(listAvailablePermissions => ({...listAvailablePermissions, isLoading: false}))
            }
            closeLoading()
        }catch(error: any){
            setListAvailablePermissions(listAvailablePermissions => ({...listAvailablePermissions, isLoading: false}))
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])
    const [formSearchAvailable, setFormSearchAvailable] = useState('')
    const [formSearchGranted, setFormSearchGranted] = useState('')





    
    
    useEffect(() => {
        if (!isEdit){
            fetchPermissions();
        }
    },[fetchPermissions, isEdit])

    const handleCreate = () => {
        setView('create')
        setFormData(formData => ({...formData,
            id : {...formData.id, value : ''},
            nama : {...formData.nama, value : ''},
        }));
        fetchPermissions();
        setIsEdit(false);
        setFormSearchAvailable('');
        setFormSearchGranted('');
        setListGranted(listGranted => ({...listGranted, data: [], ori: []}))
    }


    
    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleChangeSearch = (e: any) => {
        const { name, value } = e.target;
        setPageValue((pageValue: any) => ({...pageValue, [name] : value}))
    }
    
    const handleKeyDownSearch = (e: any) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }
    
    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handlePaginate = (page: any) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue((pageValue: any) => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    const handleSelectedAvailable = (post: any) => {
        let tmp : any = [...selectedPermissions];
        let index = tmp.findIndex((x: any) => x.id === post.id)
        
        
        if (index === -1){
            tmp.push(post)            
        }else{
            tmp.splice(index, 1)            
        }        
        setSelectedPermissions(tmp);
    }

    const handleSelectedGranted = (post: any) => {
        let tmp : any = [...selectedGranted];
        let index = tmp.findIndex((x: any) => x.id === post.id)
        
        
        if (index === -1){
            tmp.push(post)            
        }else{
            tmp.splice(index, 1)            
        }        
        setSelectedGranted(tmp);
    }


    const handleSelectedToGranted = () => {
        let tmpGranted = [...listGranted.data];        
        tmpGranted = tmpGranted.concat(selectedPermissions);
        
        const tmpAvailablePermisiions = listAvailablePermissions.data.filter((x: any) => !selectedPermissions.some((a: any) => a.id === x.id));
        const tmpOriAvailablePermisiions = listAvailablePermissions.ori.filter((x: any) => !selectedPermissions.some((a: any) => a.id === x.id));
        setListAvailablePermissions(listAvailablePermissions => ({...listAvailablePermissions, data: tmpAvailablePermisiions, ori : tmpOriAvailablePermisiions}));
        setListGranted(listGranted => ({...listGranted, data: tmpGranted, ori : tmpGranted}))

        setSelectedPermissions([]);
    }

    const handleAvailableToGranted = () => {
        setListGranted(listGranted => ({...listGranted, data: [...listGranted.data, ...listAvailablePermissions.data], ori : [...listGranted.data, ...listAvailablePermissions.data]}));
        setListAvailablePermissions(listAvailablePermissions => ({...listAvailablePermissions, data : [], ori : []}))
    }

    const handleSelectedGrantedToAvailable = () => {
        let tmpAvailable = [...listAvailablePermissions.data];
        tmpAvailable = tmpAvailable.concat(selectedGranted);
        

        // return;
        const tmpGranted = listGranted.data.filter((x: any) => !selectedGranted.some((a: any) => a.id === x.id));
        const tmpOriGranted = listGranted.ori.filter((x: any) => !selectedGranted.some((a: any) => a.id === x.id));
        setListGranted(listGranted => ({...listGranted, data: tmpGranted, ori: tmpOriGranted}));
        setListAvailablePermissions(listAvailablePermissions => ({...listAvailablePermissions, data: tmpAvailable, ori : tmpAvailable}))

        setSelectedGranted([]);
    }



    const handleGrantedToAvailable = () => {
        let tmpSelectedGranted = [...listGranted.data];


        setListAvailablePermissions(listAvailablePermissions => ({...listAvailablePermissions, data: [...listAvailablePermissions.data, ...tmpSelectedGranted]}));
        setListGranted(listGranted => ({...listGranted, data : []}))
    }

    const handleSubmit = async() => {
        try{
            let obj = {...formData}
            let countError = 0;
            let tmp : any= {}
            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    post.showError= true;
                    countError++;
                }else{
                    tmp[index] = post.value
                }
                    return true;
            })
            
            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Error',
                    subText: 'Lengkapi data',
                    color: 'danger'
                }))
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            newForm.append('permissions', JSON.stringify(listGranted.data));
            
            let res: any;
            
            if (isEdit){
                res = await api.put(`${config.endPoint.permissionGroup}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.permissionGroup}`, newForm).then(res => res.data);
            }
            if (res){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Success',
                    subText: 'Data berhasil disimpan',
                    color: 'success'
                }))
            
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
            
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            setView('list');
        }catch(error: any){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            catchFetching(error);
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleEdit = async(post:any) => {
        console.log('handle Edit >>>')
        showLoading();
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.permissionGroup}${post.id}/`).then(res => res.data);
            if (res){
                setFormData(formData => ({...formData,
                    nama : {...formData.nama, value : res.results.name},
                    id : {...formData.id, value : res.results.id}
                }))
                setListAvailablePermissions((listAvailablePermissions: any) => ({...listAvailablePermissions, data : res.results.list_permissions, ori : res.results.list_permissions}))
                setListGranted((listGranted: any) => ({...listGranted, data : res.results.detail, ori: res.results.detail}));
                setView('create');
                setIsEdit(true);
                setFormSearchAvailable('');
                setFormSearchGranted('');
            }
            closeLoading();            
            setIsLoading(false);
        }catch(error: any){
            setIsLoading(false);
            closeLoading();
            const { response } = error;
            let msg = 'Unable to fetchData data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleDelete = (params: any) => {
        setModalValue(modalValue => ({...modalValue,
            show: true,
            text: `Apakah anda yakin akan menghapus group permissions ${params.name} ?`,
            id : params.id,
            title : 'Konfirmasi',
        }))
    }
    
    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.permissionGroup}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Delete Success',
                    subText: 'Data berhasil di delete',
                    color: 'success'
                }))
    
                setTimeout(() => {
                    handleCloseAlert();
                }, config.timeOutValue)
            }
            handleCloseModal()
        }catch(error: any){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to delete data'
    
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
    
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleCancel = () => {
        setView('list');
    }

    // search
    const handleChangeAvailable = (e: any) => {        
        const { value } = e.target;        
        setFormSearchAvailable(value);
        let array = [...listAvailablePermissions.ori]
        

        let res =  array.filter((wine: any) =>
            Object.values(wine).some((val: any) => 
                String(val).toLowerCase().includes(value) 
            )
        );
        setListAvailablePermissions((listAvailablePermissions:any) => ({...listAvailablePermissions, data : res}));
    }

    const handleChangeGranted = (e: any) => {
        const { value } = e.target;        
        setFormSearchGranted(value);
        let array = [...listGranted.ori];

        let res =  array.filter((wine: any) =>
            Object.values(wine).some((val: any) => 
                String(val).toLowerCase().includes(value) 
            )
        );
        setListGranted(listGranted => ({...listGranted, data : res}));
    }



    return {
        isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert, 
        modalValue, setModalValue, handleCloseModal, formData, setFormData,
        isEdit, setIsEdit, view, setView, listData, setListData, pageValue,
        handleEdit, handleCreate, handleChangeSearch, handleKeyDownSearch, handleSearch,
        handlePaginate, listAvailablePermissions, handleSelectedAvailable, 
        handleSelectedToGranted, selectedPermissions, selectedGranted, handleSelectedGranted,
        listGranted, handleAvailableToGranted, handleSelectedGrantedToAvailable,
        handleGrantedToAvailable, handleSubmit,  handleDelete, handleSubmitDelete,         
        formSearchAvailable, handleChangeAvailable, formSearchGranted, handleChangeGranted,
        handleCancel, 
    }
}