import { Icon } from "@iconify/react";
import InputLoginComp from "../../../components/Atoms/InputLoginComp";
import { useServiceLogin } from "./serviceLoginFunction";


const ServiceLogin = () => {
    const { 
        formData, handleChange, handleBlur, handleShowPassword, 
        handleSubmit, title, ref, formSignUp, handleChangeSignUp, handleBlurSignUp, 
        handleSubmitSignUp, handleSignUp, handleSignIn, isLogin, alertValue, handleChangeDate,
        isLoading
    } = useServiceLogin();

    
    
    return(
        <>
            { isLogin ?
                <></>
                :

                <div className='w-full h-screen bg-gradient-to-r from-blue-400 to to-blue-800 font-poppins flex flex-col items-center justify-center relative'>
                    <div className='text-white text-[34px] font-bold mb-5'>{title}</div>

                    <div className="wrapper animated-signin  w-[400px] relative h-[70%] max-h-[700px]  flex flex-col items-center justify-center" ref={ref}>
                        { alertValue.show &&
                            <div className='absolute z-50 top-[80px]  w-[100%] px-[30px]'>
                                <div className='bg-red-100 text-red-500 text-[12px] text-center py-2 px-2 rounded-md'>
                                    { alertValue.text }
                                </div>
                            </div>
                        }
                        <div className="bg-white w-[100%] h-[100%] sign-in  absolute flex flex-col  items-center shadow-xl rounded-md px-[30px] py-[30px]">
                            <div className='font-bold text-[34px] text-gray-600'>Masuk</div>                                                        
                            <InputLoginComp
                                labelName="Username"
                                rooClassName='mt-[95px]'
                                value={formData.username.value}
                                name='username'
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />                        
                            <InputLoginComp
                                labelName="Password"
                                rooClassName='mt-[20px]'
                                value={formData.password.value}
                                name='password'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                showPassword={formData.password.showPassword}
                                handleShowPassword={() => handleShowPassword(formData.password.name)}
                                tipe={formData.password.type}
                            />    
                            
                            <div className='flex flex-row justify-end w-full mt-1'>

                                <button className=' text-blue-800 text-sm ' onClick={() => handleSignUp('forgotpassword')}>Lupa password ?</button>
                            </div>
                            <button 
                                className={`submit-btn w-full bg-gradient-to-r from-blue-400 to to-blue-800 mt-12 py-2 text-white rounded-lg shadow-lg flex flex-row justify-center gap-2 items-center ${isLoading && 'opacity-[0.5]'}`} 
                                onClick={handleSubmit}
                                disabled={isLoading}
                            >
                                { isLoading &&
                                    <Icon icon="eos-icons:loading" fontSize={24}/> 
                                }
                                Masuk
                            </button>
                            <div className='mt-2 text-sm'>Belum Punya Akun ? <button className="signin-link font-bold text-blue-500" onClick={() => handleSignUp('signup')}> Daftar</button></div>
                        </div>

                        <div className="bg-white w-[100%] h-[100%] sign-up absolute flex flex-col py-[30px] items-center shadow-xl rounded-md px-[30px]">
                            <div className='font-bold text-[34px] text-gray-600'>Daftar</div>
                            { Object.entries(formSignUp).map(([index, post]) => {
                                // let myIndex : number = index
                                return(
                                    <InputLoginComp key={index}
                                        labelName={post.label}
                                        rooClassName={`${index === 'nama' ? 'mt-[55px]' : 'mt-[20px]'}`}
                                        value={post.value}
                                        // value={post.type === 'date' ? post.value === '' ? new Date() : post.value : post.value}
                                        name={post.name}
                                        onChange={handleChangeSignUp}
                                        onChangeDate={(e) => handleChangeDate(e, post.name)}
                                        onBlur={handleBlurSignUp}
                                        obj={post.obj}
                                        tipe={post.type}
                                        readOnly={false}
                                        dateValue={post.value === '' ? null : post.value}                             
                                    />
                                )
                            })}
                            <button 
                                className={`submit-btn w-full bg-gradient-to-r from-blue-400 to to-blue-800 mt-12 py-2 text-white rounded-lg shadow-lg flex flex-row justify-center gap-2 items-center ${isLoading && 'opacity-[0.5]'}`} 
                                onClick={handleSubmitSignUp}
                                disabled={isLoading}
                            >
                                { isLoading &&
                                    <Icon icon="eos-icons:loading" fontSize={24}/> 
                                }
                                Daftar
                            </button>
                            <div className='mt-2 text-sm'>Sudah memiliki akun ? <button className="signin-link font-bold text-blue-500" onClick={handleSignIn}> Masuk</button></div>
                        </div>

                        
                    </div>
                </div>
            }
        </>
    )
}
export default ServiceLogin;