import { Bars, Blocks, FallingLines } from  'react-loader-spinner'
import { LoadingProps } from '../../@types/@types.theme';


const Loading = ({
	isAbsolute,
	className,
	variant="Bars",
	loadingClassName,
	height,
	width,
	color,		
} : LoadingProps) => {
	
	// const { themeColor } = useContext(ThemeContext);
	return (
		<div className={`${isAbsolute ? 'absolute top-1/2 left-1/2' : 'flex flex-col'} ${className}`}>
			{ variant === 'Blocks' &&
				<Blocks
					visible={true}
					height={height}
					width={width}
					color={color}
					ariaLabel="blocks-loading"
					wrapperStyle={{}}
					wrapperClass={loadingClassName}
				/>
			}
			{ variant === 'Bars' &&
				<Bars
					visible={true}
					height={height}
					width={width}
					color={color}
					ariaLabel="blocks-loading"
					wrapperStyle={{}}
					wrapperClass={loadingClassName}
				/>
			}
			{ variant === 'falling_lines' &&
				<FallingLines 
					visible={true}
					height={height}
					width={width}
					color={color}
					// ariaLabel="blocks-loading"
					// wrapperStyle={{}}
					// wrapperClass={loadingClassName}
				/>
			}
			{ variant === 'skeleton' &&
				<div className='animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center w-full'>
					<div 
						className={`bg-gray-200 rounded-full dark:bg-gray-700 mb-4 ${loadingClassName ? loadingClassName : 'w-48 h-2.5'}`}
						// style={{width: width, height: height}}
					></div>
				</div>
			}
		</div>
	);
};
// Loading.propTypes = {
// 	isAbsolute : PropTypes.bool,
// 	className : PropTypes.string,
// 	variant : PropTypes.string,
// 	loadingClassName : PropTypes.string,
// 	height : PropTypes.any,
// 	width : PropTypes.any,
// 	color : PropTypes.string,
// }


// Loading.defaultProps = {
// 	isAbsolute : false,
// 	variant : 'Bars',
// 	color : localStorage.getItem("theme-color"),
// 	height: 40,
// 	window: 40
// }
export default Loading;
