import { useParams } from "react-router-dom";


const CheckYourEmailPage = () => {
    const { id } = useParams();
    return(
        <div className='w-full h-full min-h-screen bg-white flex flex-col justify-center items-center px-[20px] font-poppins'>
            <div className='w-[30%] min-w-[300px]'>
                <img src={require('../../../assets/images/checkemail.jpg')} alt="img" />
                <div className='flex flex-row justify-end -mt-[30px] text-sm text-blue-600'>
                    <a href="https://www.freepik.com/">Designed by Freepik</a>
                </div>
            </div>
            <div className='text-center w-[80%] max-w-[400px]'>
                <div className='mt-10 text-[24px] mb-1'>
                    Verify Your Email
                </div>
                <div className='text-center'>
                    Check your email {id} & click the link to Activate your account
                </div>
            </div>
        </div>
    )
}
export default CheckYourEmailPage;