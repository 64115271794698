import { Outlet, Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { config } from "../../constants/config";


const PrivateRoute = () => {
  const [cookies] = useCookies([config.cookiesName]);
  const isAuthenticated = () => {
    return !!cookies[config.cookiesName]; 
  };

  if (isAuthenticated()) {
    return <Outlet />;
  } else {
    return <Navigate to="/authentication" replace />;
  }
};

export default PrivateRoute;
