import {  useContext, useState } from "react";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";


// import Resizer from "react-image-file-resizer";
import { contextTypeProps } from "../@types/@types.theme";
import { UserContext } from "../context/UserContext";
import { config } from "../constants/config";

export function  useGlobalFunction(){
    const [isLoading, setIsLoading] = useState(false);
    
    const cookies = new Cookies();
    const navigate = useNavigate();
    const {myLoading, setMyLoading} = useContext(UserContext) as contextTypeProps;
    

    const [alertValue, setAlertValue] = useState({
        show : false,
        text : 'Anda terjadi error',
        color : 'danger',
        subText :  'Lengkapi Data',
        icon : '',
    });
    const [modalValue, setModalValue] = useState({
        show: false,
        tipe: '',
        content : 'list',
        text : '',
        title : '',
        id : 0,
        params : null,
        url : null,
        isEdit : false,
    })

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({...alertValue, show: false}))
    }

    const [pageValue, setPageValue] = useState({
		page : 1,
		search : '',
		offset : 0,
		obj : [],        
        lastPage : 1,        
        totalRecord : 1,
        state_approval : 'all',
	});
    

    const handleCloseModal = () => {        
        setModalValue((modalValue: any) => ({
            ...modalValue,
            show: false,
            tipe: '',
            content : 'list',
            text : '',
            title : '',
        }))
    }

    const catchFetching = (error:any) => {       
        let status = error && error.response && error.response.status ? error.response.status : '';
        if (status && [401, 403].includes(status)) {        
            console.log('status 401')                
            cookies.remove(config.cookiesName, { path: '/' });	
            return navigate('/admin/login')           
        }
    }    

    const closeLoading = () => {
        setMyLoading(false)
    }

    const showLoading = () => {
        setMyLoading(true)
    }

    const getColor = (params:number) => {
        if (params === 3){
            return {
                backgroundColor:  '#D9D9D9',
                color : '#A9A9A9'
            }
        }else if(params === 2){
            return {
                backgroundColor:  'rgb(255, 205, 205)',
                color : 'rgb(255, 15, 15)'
            }
        }else if(params === 1){
            return {
                backgroundColor:  'rgb(224, 248, 229)',
                color : 'rgb(1, 184, 26)'
            }
        }else{
            return {
                backgroundColor:  '#5c5c5c',
                color : '#fff'
            }
        }
    }

    const toTitleCase = (str: string) =>  {
        return str.replace(
          /\w\S*/g,
          text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
        );
    }

    // const resizeFile = (file, type) => new Promise(resolve => {
    //     Resizer.imageFileResizer(file, 800, 800, 'JPEG', 100, 0,
    //     uri => {
    //       resolve(uri);
    //     }, type );
    // });

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, catchFetching, closeLoading, myLoading, showLoading, getColor, toTitleCase,
        // resizeFile
    }
}