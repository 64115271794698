import { useContext, useEffect, useRef, useState  } from "react";
import {  Outlet,   useNavigate } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ThemeContext } from "../../context/ThemeContext";
import { themeTypeProps } from "../../@types/@types.theme";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import Sidebar from "./Sidebar";
import Header from "./Header";
import ButtonRipple from "../Atoms/ButtonRipple";
import { Icon } from "@iconify/react";
import { themeColors } from "../../constants/theme";
import Drawer from "../Atoms/Drawer";
import Radio from "../forms/Radio";
import ButtonDarkMode from "../Atoms/ButtonDarkMode";
import Popover from "../Atoms/Popover";
import Badge from "../Atoms/Badge";
import Avatar from "../Atoms/Avatar";
import Button from "../Atoms/Button";

const Layout = () => {
    const [sideOpen, setSideOpen] = useLocalStorage("side-open", "");
    const { 
        themeSkin,
        themeColor,
        setThemeColor,
        setThemeSkin,
        setNavbarType,
        navbarType,
        setColorMode,
        colorMode
    } = useContext(ThemeContext) as themeTypeProps;
    const themeConfigRef = useRef();
    const mainRef = useRef<any>();
    const navigate = useNavigate();
    const [themeConfig, setThemeConfig] = useState(false);
    const [toTop, setToTop] = useState(false);

    useOnClickOutside(themeConfigRef, () => setThemeConfig(false));

    useEffect(() => {
		if (mainRef.current) {
			const main = mainRef.current;
			main.addEventListener("scroll", () => {
				if (main.scrollTop > 20) {
					setToTop(true);
				} else {
					setToTop(false);
				}
			});

			return () => {
				main.removeEventListener("scroll", () => {});
			};
		}
	}, [mainRef]);


	
	

    return(
        <>
            <main 
                ref={mainRef}
                className={`w-full scroll-smooth text-base-300 dark:text-base-200 font-light h-screen overflow-y-auto custom-scroll font-poppins ${
					themeSkin === "default"
						? "bg-base-50 dark:bg-base-700"
						: "bg-white dark:bg-base-700"
				} pb-20 md:pb-0`}    
            >
                <Sidebar sideOpen={sideOpen} setSideOpen={setSideOpen} />
                <div
					className={`w-full transition-[padding] duration-500 ${
						sideOpen ? "lg:pl-64" : "lg:pl-20"
					}`}
				>
					<Header sideOpen={sideOpen} setSideOpen={setSideOpen} />
					<section className={`min-h-screen w-full p-6`}>
						{/* <LoadingTop/> */}
						<Outlet />
					</section>
				</div>
            </main>

            {/* To Top Button */}
			<ButtonRipple
				style={{
					backgroundColor: themeColor,
				}}
				onClick={() => mainRef.current.scrollTo(0, 0)}
				className={`fixed text-xl text-white shadow-lg bottom-24 md:bottom-10 right-6 md:right-12 z-30 p-2 rounded-lg transition-opacity duration-300 ${
					toTop
						? "opacity-100 pointer-events-auto"
						: "opacity-0 pointer-events-none"
				}`}
			>
				<Icon icon="mingcute:arrow-up-fill" fontSize={24} />
			</ButtonRipple>

            <ButtonRipple
				style={{
					backgroundColor: themeColor,
				}}
				onClick={() => setThemeConfig(true)}
				className={` fixed text-xl text-white shadow-lg bottom-10 z-30 p-2 rounded-l-lg hidden lg:block transition-[right] duration-500 ${
					themeConfig ? "-right-10" : "right-0"
				}`}
			>
				<Icon icon="icon-park-solid:setting" fontSize="24" />
			</ButtonRipple>

            <Drawer
				title="CUSTOMIZE THEME"
				description="Make your own theme"
				dismiss
				open={themeConfig}
				setOpen={setThemeConfig}
			>
				{/* Theme */}
				<div className="p-5 border-b border-neutral-200 dark:border-base-500">
					<div className="mb-2 font-semibold text-xs tracking-widest">
						THEME
					</div>
					{/* Primary Color */}
					<div className="mb-4">
						<div className="text-sm mb-1">Primary Color</div>
						<div className="flex flex-wrap gap-5">
							{themeColors.map((color, colorIdx) => (
								<ButtonRipple
									key={colorIdx}
									onClick={() => {
										setThemeColor(color);
									}}
									style={{
										backgroundColor: color,
										boxShadow:
											color === themeColor ? `0px 0px 10px 0px ${color}60` : "",
									}}
									className="w-10 h-10 rounded text-white flex items-center justify-center"
								>
									{color === themeColor && <Icon icon="heroicons-solid:check" fontSize={24} />}
								</ButtonRipple>
							))}
						</div>
					</div>

					{/* Skins */}
					<div className="mb-4">
						<div className="text-sm mb-1">Skins</div>
						<div>
							<Radio
								color={themeColor}
								value={themeSkin}
								onChange={setThemeSkin}
								options={[
									{
										label: "Default",
										value: "default",
									},
									{
										label: "Bordered",
										value: "bordered",
									},
								]}
							/>
						</div>
					</div>

					{/* Theme */}
					<div>
						<div className="text-sm mb-1">Theme</div>
						<div>
							<Radio
								color={themeColor}
								value={colorMode}
								onChange={setColorMode}
								options={[
									{
										label: "Light",
										value: "light",
									},
									{
										label: "Dark",
										value: "dark",
									},
								]}
							/>
						</div>
					</div>
				</div>
				
				<div className="p-5 border-b border-neutral-200 dark:border-base-500">
					<div className="mb-2 font-semibold text-xs tracking-widest">
						LAYOUT
					</div>
					{/* Navbar Type */}
					<div>
						<div className="text-sm mb-1">Navbar Type</div>
						<div>
							<Radio
								color={themeColor}
								value={navbarType}
								onChange={setNavbarType}
								options={[
									{
										label: "Sticky",
										value: "sticky",
									},
									{
										label: "Static",
										value: "static",
									},
									{
										label: "Hidden",
										value: "hidden",
									},
								]}
							/>
						</div>
					</div>
				</div>
			</Drawer>

            <div className='absolute bottom-0 left-0 w-full h-fit bg-white visible md:invisible md:h-0 md:hidden'>
				<div className="flex flex-row py-[10px] px-[20px] justify-between items-center">
					<div>
						<div
							onClick={() => setSideOpen(true)}
							className="cursor-pointer block lg:hidden"
						>
							<Icon icon="mingcute:menu-fill" />
						</div>
					</div>
					<div>
						<ButtonDarkMode />
					</div>
					<div>
						<Popover
							placement="bottom-end"
							spacing={20}
							button={
								<ButtonRipple className="p-2 rounded-full transition-[background] hover:bg-neutral-200 dark:hover:bg-base-500">
									<Badge
										hidden
										size="xl"
										placement="top-end"
										skidding={8}
										color="success"
									>
										<div className="text-2xl">
                                            <Icon icon="ph:bell-bold" />
										</div>
									</Badge>
								</ButtonRipple>
							}
						>
							<div className="text-sm min-w-[200px] rounded-lg">
								<button className="p-4 py-2 hover:bg-slate-500 rounded cusrp">
									Menu 1
								</button>
							</div>
						</Popover>
					</div>
					<div>
						<Popover
							placement="bottom-end"
							spacing={20}
							button={
								<ButtonRipple className="rounded-full">
									<Badge size="sm" placement="right-end" color="success">
										<Avatar color="primary">AD</Avatar>
									</Badge>
								</ButtonRipple>
							}
						>
							<div className="text-sm min-w-[200px] rounded-lg p-2">
								<Button
									onClick={() => navigate("/authentication")}
									variant="text"
									block
									color="danger"
								>
									Logout
								</Button>
							</div>
						</Popover>
					</div>
				</div>
			</div>
        </>
    )
}
export default Layout