import { Fragment } from 'react';
import { TbSearch } from "react-icons/tb";
import { searchProps } from '../../@types/@types.theme';

const Search = ({
    placeholder,
    onChange,
    handleSearch,
    value,
    inputClassName,
    className
}: searchProps) => {    
    return(
        <Fragment>
            <div className={`relative  w-full flex justify-end ${className}`}>
                <input 
                    className={`border border-gray-300 rounded-[6px] py-[10px] pl-[14px]  w-full ${inputClassName} text-[14px] block leading-tight 
                    focus:outline-1 
                    focus:outline-none 
                    focus:outline-blue-400 focus:border-none placehodel:pl-1 placeholder:opacity-50`}
                    placeholder={placeholder} 
                    onChange={onChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter'){
                            handleSearch()
                        }
                    }}
                    value={value}     
                    name="search"               
                />
                <button className="absolute bottom-[0px] right-[10px] h-full" onClick={handleSearch}>
                    <div className='flex flex-row items-center'>
                        <TbSearch className='text-xl'/>
                    </div>
                </button>
            </div>
        </Fragment>
    )
}


export default Search