

import { useRef } from 'react';
import '../../../assets/styles/login.css'
import InputLoginComp from '../../../components/Atoms/InputLoginComp';
import { useAuthFunction } from './authFunction';
import { Icon } from '@iconify/react';




const AuthenticationPage = () => {    
    const { 
        formSignUp, handleChangeSignUp, handleSubmitSignUp,
        handleBlurSignUp, 
        formData, handleChange, handleBlur, handleShowPassword,
        handleSubmit, viewSignUp, setViewSignUp, formForgotPassword, handleBlurForgotPassword, handleChangeForgotPassword,
        handleSubmitForgotPassword, alertValue, isLoading
    } = useAuthFunction();
    const ref = useRef<any>(null);
    
    const handleSignIn = () => {
        const content :any  = ref.current;
        content.classList.add("animated-signup");
        content.classList.remove("animated-signin");
    }
    
    const handleSignUp = (params:any=null) => {        
        if (params){
            setViewSignUp(params);
        }
        const content : any = ref.current;
        content.classList.add("animated-signin");
        content.classList.remove("animated-signup");

    }

    return(
        <div className='w-full h-screen bg-gradient-to-r from-blue-400 to to-blue-800 font-poppins flex flex-row items-center justify-center relative'>
            { alertValue.show &&            
                <div className='top-[10%] px-5 bg-red-100 absolute rounded-lg text-red-600 py-1'>
                    <div>{alertValue.subText}</div>
                </div>
            }
            <div className="wrapper animated-signin   w-[400px] relative h-[70%] max-h-[500px]  flex flex-col items-center justify-center" ref={ref}>
                { viewSignUp === 'signup' &&
                    <div className="bg-white w-[100%] h-[100%] sign-up absolute flex flex-col py-[30px] items-center shadow-xl rounded-md px-[30px]">
                        <div className='font-bold text-[34px] text-gray-600'>Daftar</div>
                        <InputLoginComp
                            labelName="Nama"
                            rooClassName='mt-[95px]'
                            value={formSignUp.nama.value}
                            name='nama'
                            onChange={handleChangeSignUp}
                            onBlur={handleBlurSignUp}
                            />
                        <InputLoginComp
                            labelName="Email"
                            rooClassName='mt-[20px]'
                            value={formSignUp.email.value}
                            name='email'
                            onChange={handleChangeSignUp}
                            onBlur={handleBlurSignUp}
                        />                        
                        <button 
                            className={`submit-btn w-full bg-gradient-to-r from-blue-400 to to-blue-800 mt-12 py-2 text-white rounded-lg shadow-lg flex flex-row justify-center gap-2 items-center ${isLoading && 'opacity-[0.5]'}`} 
                            onClick={handleSubmitSignUp}   
                            disabled={isLoading}                         
                        >
                            { isLoading &&
                                <Icon icon="eos-icons:loading" fontSize={24}/> 
                            }
                            Daftar
                        </button>
                        <div className='mt-2 text-sm'>Sudah memiliki akun ? <button className="signin-link font-bold text-blue-500" onClick={handleSignIn}> Masuk</button></div>
                    </div>
                }
                { viewSignUp === 'forgotpassword' &&
                    <div className="bg-white w-[100%] h-[100%] sign-up absolute flex flex-col py-[30px] items-center shadow-xl rounded-md px-[30px]">
                        <div className='font-bold text-[24px] text-gray-600'>Lupa Password</div>
                        <InputLoginComp
                            labelName="Email"
                            rooClassName='mt-[95px]'
                            value={formForgotPassword.email.value}
                            name='email'
                            onChange={handleChangeForgotPassword}
                            onBlur={handleBlurForgotPassword}
                        />
                        <button className="submit-btn w-full bg-gradient-to-r from-blue-400 to to-blue-800 mt-12 py-2 text-white rounded-lg shadow-lg" onClick={handleSubmitForgotPassword}>Kirim Link Reset Password</button>
                        <div className='mt-2 text-sm'>Sudah ingat password ? <button className="signin-link font-bold text-blue-500" onClick={handleSignIn}> Masuk</button></div>
                    </div>
                }

                    <div className="bg-white w-[100%] h-[100%] sign-in absolute flex flex-col  items-center shadow-xl rounded-md px-[30px] py-[30px]">
                        <div className='font-bold text-[34px] text-gray-600'>Masuk</div>
                        <InputLoginComp
                            labelName="Username"
                            rooClassName='mt-[95px]'
                            value={formData.username.value}
                            name='username'
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />                        
                        <InputLoginComp
                            labelName="Password"
                            rooClassName='mt-[20px]'
                            value={formData.password.value}
                            name='password'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            showPassword={formData.password.showPassword}
                            handleShowPassword={() => handleShowPassword(formData.password.name)}
                            tipe={formData.password.type}
                        />    
                        
                        <div className='flex flex-row justify-end w-full mt-1'>

                            <button className=' text-blue-800 text-sm ' onClick={() => handleSignUp('forgotpassword')}>Lupa password ?</button>
                        </div>
                        <button className="submit-btn w-full bg-gradient-to-r from-blue-400 to to-blue-800 mt-12 py-2 text-white rounded-lg shadow-lg" onClick={handleSubmit}>Masuk</button>
                        <div className='mt-2 text-sm'>Belum Punya Akun ? <button className="signin-link font-bold text-blue-500" onClick={() => handleSignUp('signup')}> Daftar</button></div>
                    </div>
            </div>
        </div>
    )
}
export default AuthenticationPage;