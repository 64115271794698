export const config : any= {
    apiBaseUrl: {                
        development: "http://127.0.0.1:8001",      
        // production : "https://besso.identifikasi.com",      
        production : "https://besso.identifikasi.com",      
    },
    wsServer : {
        development : "ws://127.0.0.1:8000/ws/",
        production : '',
    },
    localStorageKey : {      
        USER: "__ssosmartftth",      
    },    
    cookiesName : '__sso_smartftth',    
    cookiesNameToken : 'sso_smartftth_token',
    itemPerPage : 40,
    timeOutValue : 10000,
    endPoint : {
        // auth
        login : `/auth/api/v1/login/`,
        signUp : `/auth/api/v1/signup/`,
        confirmEmail : `/auth/api/v1/confirm-email/`,
        loginTenant : `/tenant/api/v1/login/`,
        signUpTenant : `/tenant/api/v1/sign-up/`,
        confirmEmailTenant : `/tenant/api/v1/confirm-email/`,
        createPasswordTenant : `/tenant/api/v1/create-password/`,
        
        tenant : `/master/api/v1/tenant/`,
        tenantTenant : `/master/api/v1/tenant/tenant/`,
        groupTenant : `/master/api/v1/group-tenant/`,
        checkIsLogin : `/tenant/api/v1/check-islogin/`,

        // master
        permissionGroup : '/auth/api/v1/group-permissions/',      
        permissions : '/auth/api/v1/permissions/',
        groupDefault : `/auth/api/v1/group-default/`,
        userRole : '/auth/api/get-all-role/',

        test :  '/master/api/v1/tenant/',

        // kontak
        kontak : 'kontak/api/v1/kontak/',
        kontakApprove : 'kontak/api/v1/approve/',
        
    }
}