import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../hooks/globalFunction";
import { UserContext } from "../../context/UserContext";
import { contextTypeProps } from "../../@types/@types.theme";
import { config } from "../../constants/config";
import api from "../../constants/api";
import { pageObj } from "../../hooks/pageObj";

interface formDataProps {
    [key: string] : {
        name : string,
        type: string,
        label : string,
        value : any,
        required : boolean,
        showError : boolean,
        show: boolean,
        hideLabel? : boolean,
        obj? : any[],
        file? : any,
    }
}



export function useGroupTenant(){
    const { isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        modalValue, setModalValue, handleCloseModal, pageValue, setPageValue, closeLoading, showLoading
    } = useGlobalFunction();
    const { modalState, modalDispatch } = useContext(UserContext) as contextTypeProps;
    const [listData, setListData] = useState({isLoading : isLoading, data: []});
    const [view, setView] = useState<string>('list');
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [listTenant, setListTenant] = useState([]);
    const [formData, setFormData] = useState<formDataProps>({
        id : {
            name : 'id',
            type: 'text',
            label : 'Group Name',
            value : '',
            required : false,
            showError : false,
            show: true,
        },
        nama : {
            name : 'nama',
            type: 'text',
            label : 'Group Name',
            value : '',
            required : true,
            showError : false,
            show: true,
        },
        url_check_user : {
            name : 'url_check_user',
            type: 'text',
            label : 'URL Check User',
            value : '',
            required : true,
            showError : false,
            show: true,
        },
        login_title : {
            name : 'login_title',
            type: 'text',
            label : 'Login Title',
            value : '',
            required : true,
            showError : false,
            show: true,
        },
        logo : {
            name : 'logo',
            type: 'image',
            label : 'Login Title',
            value : '',
            required : false,
            showError : false,
            show: true,
        },
        url_post_register : {
            name : 'url_post_register',
            type: 'text',
            label : 'URL Post Register User',
            value : '',
            required : true,
            showError : false,
            show: true,
        },
    })
    const [customField, setCustomField] = useState<any>([]);

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        showLoading()
        try{
            const params : any = {
                offset : offset,
                limit : limit,
            }
            if (q) {
                params['q'] = q
            }
            const res = await api.get(`${config.endPoint.groupTenant}`, {params: params}).then(res => res.data);
            if (res){
                setListData((listData: any) => ({...listData, isLoading: false, data: res.results}))
                setPageValue((pageValue: any) => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }else{
                setListData((listData: any) => ({...listData, isLoading: false}))
            }
            closeLoading()
        }catch(error: any){
            setListData((listData: any) => ({...listData, isLoading: false}))
            closeLoading()
            const { response } = error;
            let msg = 'Unable to fetch data'
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue((alertValue: any) => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            setTimeout(() => {
                setAlertValue((alertValue: any) => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
        // eslint-disable-next-line 
    },[])
    
    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleCreate = () => {
        setIsEdit(false);
        setView('create');
        setFormData((formData:any) => ({...formData,
            id : {...formData.id, value : ''},
            nama : {...formData.nama, value : ''},
            url_check_user : {...formData.url_check_user, value : ''},
            login_title : {...formData.login_title, value : ''},
            logo : {...formData.logo, value : ''},
            url_post_register : {...formData.url_post_register, value : '' || ''},
        }))
        setListTenant([]);
        setCustomField([]);
    }

    const handleCancel = () => {
        setView('list');
    }

    const handleChangeSearch = (e:any) => {
        const { name, value } = e.target;
        setPageValue((pageValue: any) => ({...pageValue, [name] : value}))
    }
    
    const handleKeyDownSearch = (e: any) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }
    
    const handleSearch = () => {
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handlePaginate = (page:any) => {
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue((pageValue: any) => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    const handleShowTenant = () => {
        modalDispatch({
            type: 'TENANT',
            onClose : closeModalHandler,
            onClick : onClickTenant,
            query_type: 'group'
        })
    }

    const onClickTenant = (post: any) => {
        let array : any= [...listTenant];
        array.push(post);
        setListTenant(array);
        closeModalHandler();
        // console.log('post is >>>>', post)
    }

    const closeModalHandler = () => {
        modalDispatch({type: 'CLOSE'})
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        
        try{
            let obj = {...formData}
            let countError = 0;
            let tmp : any = {}
            Object.entries(obj).map(([index, post]): any => {                
                if (post.required && post.value === ''){
                    post.showError= true;
                    countError++;
                }else{
                    if (['file', 'image', 'dokumen'].includes(post.type)){
                        tmp[index] = post.file
                    }else{
                        tmp[index] = post.value ? post.value : '';
                    }                    
                }
                return true;
            })



            // register field
            let iterate_custom_field : formDataProps[]= [...customField];
            let form_custom_field : any = []

            iterate_custom_field.map((post: any) => {
                let obj_customField : any= {}
                let tmp_customField : any= {...post};
                Object.entries(tmp_customField).map(([index, nilai]) : any => {
                    let myobj : any = nilai;
                    if (myobj.required && myobj.value === ''){
                        myobj['showError'] = true;
                        countError++;
                    }else{
                        obj_customField[index] = myobj.value
                    }
                    return true;
                })
                console.log('tmp_customField >>>>', obj_customField)
                form_custom_field.push(obj_customField)
                return true;
            })

            
            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Error',
                    subText: 'Lengkapi data',
                    color: 'danger'
                }))
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue);
                return
            }


            
            
            

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmp));
            newForm.append('tenants', JSON.stringify(listTenant))
            newForm.append('customField', JSON.stringify(form_custom_field))
            newForm.append('foto', tmp.logo)  

            let res : any;
            if (isEdit){
                res = await api.put(`${config.endPoint.groupTenant}${formData.id.value}/`, newForm).then((res: any) => res.data);
            }else{
                res = await api.post(`${config.endPoint.groupTenant}`, newForm).then((res: any) => res.data);
            }
            
            if (res){
                setAlertValue(alertValue => ({...alertValue,
                    show: true,
                    text : 'Success',
                    subText: 'Data berhasil disimpan',
                    color: 'success'
                }))
            
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage)
            
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)
            }
            setView('list');
        }catch(error:any){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to save data'
            
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            
            setAlertValue((alertValue: any) => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
            
            setTimeout(() => {
                setAlertValue((alertValue: any) => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleEdit = (post:any) => {     
        console.log('post is >>>>', post);   
        setIsEdit(true);
        setView('create');
        setFormData((formData: any) => ({...formData,
            nama : {...formData.nama, value : post.group_name},
            url_check_user : {...formData.url_check_user, value : post.url_check_user},
            id : {...formData.id, value : post.id},
            login_title : {...formData.login_title, value : post.login_title},
            url_post_register : {...formData.url_post_register, value : post.url_post_register},
            logo : {...formData.logo, value : post.logo},
        }))
        let tmp : any = []
        post.tenants.map((tenant:any) => {
            tmp.push({
                'id' : tenant.id,
                'nama' : tenant.nama
            })
            return true;
        })
        setListTenant(tmp);
        
        
        let tmpCustomField : any = []        
        post.field_if_unrigester?.map((field:any) => {
            const initCustomField : formDataProps = {
                key_value : {
                    name : 'key_value',
                    type: 'text',
                    label : '',
                    value : field.key_value,
                    required : true,
                    showError : false,
                    show: true,
                    hideLabel : true
                },
                label_value : {
                    name : 'label_value',
                    type: 'text',
                    label : '',
                    value : field.label_value,
                    required : true,
                    showError : false,
                    show: true,
                    hideLabel : true
                },
                type_value : {
                    name : 'type_value',
                    type: 'select',
                    label : '',
                    value : field.type_value,
                    required : true,
                    showError : false,
                    show: true,
                    hideLabel : true,
                    obj : [
                        {value: 'string', label : 'string / number'},
                        {value: 'union', label : 'Union'},
                        {value: 'date', label : 'Date'},
                    ]
                },
                union_value : {
                    name : 'union_value',
                    type: 'text',
                    label : 'Union Value, sparated by ,(comma)',
                    value : field.union_value,
                    required : field.type_value === 'union' ? true : false,
                    showError : false,
                    show: true,
                },
            }
            tmpCustomField.push(initCustomField)
            return true;
        })
        setCustomField(tmpCustomField)
        
    }

    const handleDelete = (post: any) => {
        setModalValue((modalValue: any) => ({...modalValue,
            id : post.id,
            text : `Apakah anda yakin akan menghapus group ${post.group_name} ? `,
            title : 'Konfirmasi',
            show: true
        }))
    }

    
    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await api.delete(`${config.endPoint.groupTenant}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
                setAlertValue((alertValue: any) => ({...alertValue,
                    show: true,
                    text : 'Delete Success',
                    subText: 'Data berhasil di delete',
                    color: 'success'
                }))
    
                setTimeout(() => {
                    handleCloseAlert();
                }, config.timeOutValue)
            }
            handleCloseModal()
        }catch(error: any){
            setIsLoading(false);
            const { response } = error;
            let msg = 'Unable to delete data'
    
            if (response && response.data && response.data.message){
                msg = response.data.message;
            }
            setAlertValue((alertValue: any) => ({...alertValue,
                show: true,
                text : 'Error',
                subText: msg,
                color: 'danger'
            }))
    
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false}))
            }, config.timeOutValue)
        }
    }

    const handleAddCustomField = () => {
        const initCustomField : formDataProps = {
            key_value : {
                name : 'key_value',
                type: 'text',
                label : '',
                value : '',
                required : true,
                showError : false,
                show: true,
                hideLabel : true
            },
            label_value : {
                name : 'label_value',
                type: 'text',
                label : '',
                value : '',
                required : true,
                showError : false,
                show: true,
                hideLabel : true
            },
            type_value : {
                name : 'type_value',
                type: 'select',
                label : '',
                value : 'string',
                required : true,
                showError : false,
                show: true,
                hideLabel : true,
                obj : [
                    {value: 'string', label : 'string / number'},
                    {value: 'union', label : 'Union'},
                    {value: 'date', label : 'Date'},
                ]
            },
            union_value : {
                name : 'union_value',
                type: 'text',
                label : 'Union Value, sparated by ,(comma)',
                value : '',
                required : true,
                showError : false,
                show: true,
            },
        }
        let array :any = [...customField]
        array.push(initCustomField);
        setCustomField(array);
    }

    const handleChangeCustomField = (index: number, e:any) => {
        const { name, value } = e.target;
        let array : any = [...customField];
        array[index] = {...array[index],
            [name] : {...array[index][name], value : value}
        }
        if (name === 'type_value' && value === 'union'){
            array[index] = {...array[index],
                union_value : {...array[index]['union_value'], required : true}
            }
        }else{
            array[index] = {...array[index],
                union_value : {...array[index]['union_value'], required : false}
            }
        }
        setCustomField(array);
    }

    const handleBlurCustomField = (index: number, e: any) => {
        const { name, value } = e.target;
        let array : any = [...customField];
        if  (array[index][name].required && value === ''){
            array[index] = {...array[index],
                [name] : {...array[index][name], showError : true}
            }   
        }else{
            array[index] = {...array[index],
                [name] : {...array[index][name], showError : false}
            }   
        }
        setCustomField(array);
    }

    const handleDeleteCustomField = (index: number) => {
        let array = [...customField];
        array.splice(index, 1);
        setCustomField(array);
    }

    const handleDeleteListTenant = (index:number) => {
        let array : any = [...listTenant];
        array.splice(index, 1);
        setListTenant(array);
    }

    return {
        isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert, 
        modalValue, setModalValue, handleCloseModal, formData, setFormData,
        isEdit, setIsEdit, view, setView, listData, setListData, pageValue, setPageValue, closeLoading, showLoading,
        handleShowTenant, modalState, modalDispatch, listTenant, handleSubmit, handleCreate,
        handleCancel, handleSearch, handleKeyDownSearch, handleChangeSearch, handlePaginate,
        handleEdit, handleDelete, handleSubmitDelete, customField, handleAddCustomField,
        handleChangeCustomField, handleBlurCustomField, handleDeleteCustomField, handleDeleteListTenant
    }
}