import { Fragment, useContext } from 'react';
import Button from './Button';
import { ThemeContext } from '../../context/ThemeContext';
import { PagePaginationProps, themeTypeProps } from '../../@types/@types.theme';


const PagePagination = ({
	handlePagination,
    page, 
    data,
    lastPage,	
	activeColor = "primary",
	rounded="sm",
	variant = "flat",
	size = "md",
	className
}: PagePaginationProps) => {
    const { themeColor, colorMode } = useContext(ThemeContext) as themeTypeProps;    
    // const sizePagination =
	// 	{
	// 		xs: 25,
	// 		sm: 30,
	// 		md: 35,
	// 		lg: 40,
	// 		xl: 45,
    // }[size] || size;

    const colorPagination =
		{
			primary: themeColor,
			base: "#BABCBD",
			success: "#4ED17E",
			warning: "#EEC239",
			danger: "#F26969",
			info: "#629BF8",
		}[activeColor] || activeColor;

    return (
        <Fragment>
            { data.length > 1 &&
                <div className='flex flex-row pagination gap-1'>
                    { page > 6 && 
                        <Button                            
                            size={size}
                            className="text-xs"
                            color={colorMode === "light" ? "#BABCBD95" : "#4D535595"}
                            textcolor={`${colorMode === "light" ? "#171C1E" : "white"}`}
                            variant={variant}
                            rounded={rounded}
                            onClick={() => handlePagination(1)}
                            disabled={page === 1}
                        >
                            {`<<`}
                        </Button>
                        // <button className='py-2 px-3 border border-gray-400 cursor-pointer rounded' onClick={() => handlePagination(1)}>{`<<`}</button>
                    }
                    { data.map((post, index) => {
                        return (                            
                            <Button
                                key={index}
                                size={size}
                                className="text-xs"
                                color={
                                    post === page
                                        ? colorPagination
                                        : colorMode === "light"
                                        ? "#BABCBD95"
                                        : "#4D535595"
                                }
                                textcolor={
                                    post === page
                                        ? "white"
                                        : colorMode === "light"
                                        ? "#171C1E"
                                        : "white"
                                }
                                variant={variant}
                                rounded={rounded}
                                onClick={() => handlePagination(post)}
                            >
                                {post}
                            </Button>                            
                        )
                        })}
                    { lastPage !== page &&               
                        <Button
                            size={size}
                            className="text-xs"
                            color={colorMode === "light" ? "#BABCBD95" : "#4D535595"}
                            textcolor={`${colorMode === "light" ? "#171C1E" : "white"}`}
                            variant={variant}
                            rounded={rounded}
                            disabled={page === lastPage}
                            onClick={() => handlePagination(lastPage)}
                        >
                            {`>>`}
                        </Button>
                    }
                </div>
            }
        </Fragment>
    )
}



export default PagePagination;