import { Icon } from "@iconify/react";
import Button from "../../components/Atoms/Button";
import Card from "../../components/Atoms/Card";
import Modal from "../../components/Atoms/Modal";
// import Tables from "../../components/Atoms/Tables";
import Tooltip from "../../components/Atoms/Tooltip";
import FormTextSingleComp from "../../components/forms/FormTextSingleComp";
import { useGroupTenant } from "./groupTenantFunction";
import Tables from "../../components/Atoms/Tables";
import PagePagination from "../../components/Atoms/PagePagination";
import AlertComp from "../../components/Atoms/AlertComp";


const GroupTenantPage = () => {
    const { view, formData, setFormData,
        handleShowTenant, modalState, modalDispatch, listTenant,
        handleSubmit, listData, pageValue, handleChangeSearch, handleSearch, handlePaginate,
        handleCancel, handleCreate, handleEdit, handleDelete, modalValue, handleCloseModal,
        handleSubmitDelete, setModalValue, customField, handleAddCustomField,
        handleChangeCustomField, handleBlurCustomField, handleDeleteCustomField,
        alertValue, handleCloseAlert, handleDeleteListTenant
    } = useGroupTenant();
    return(
        <>
            <AlertComp
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
                text={alertValue.text}
                subText={alertValue.subText}
                color={alertValue.color}
                icon={alertValue.icon}
            />
            <Modal
                show={modalValue.show}
                setShow={() => {
                    setModalValue((modalValue: any) => ({...modalValue, show: false}))
                }}
                width='xl'
                persistent={false}
                btnClose={false}
            >
                <div className='py-[20px] px-[30px] border-b border-b-gray-200 font-bold'>
                    {modalValue.title}
                </div>
                <div className='py-[50px] px-[30px]'>
                    { modalValue.text }
                </div>
                <div className='py-[20px] px-[30px] border-t border-t-gray-200 flex flex-row justify-end gap-2'>
                    <Button color='danger' size='md' onClick={handleSubmitDelete}>Hapus</Button>
                    <Button color='base' size='md' onClick={handleCloseModal}>Cancel</Button>
                </div>
            </Modal>

            <Modal
                show={modalState.isOpen}
                setShow={() => {
                    modalDispatch({type: 'CLOSE'})
                }}
                width='xl'
                persistent={false}
                btnClose={false}
            >
                {modalState.content}
            </Modal>
            <Card                
                isCreate={view === 'create'}
                isSearch={view === 'list'}
                searchValue={pageValue.search}
                handleCreate={handleCreate}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                onChangeSearch={handleChangeSearch}
                handleSearch={handleSearch}
            >            
                { view === 'list' &&
                    <>
                        <Tables className='border-separate rounded-sm'>
                            <Tables.Head className='bg-[#efeeee]'>
                                <tr>
                                <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>GroupName</Tables.Header>
                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Tenant</Tables.Header>
                                    <Tables.Header className='font-bold text-[13px] py-[10px]'>Action</Tables.Header>
                                </tr>
                            </Tables.Head>
                            <Tables.Body>
                                { listData?.data?.length === 0 ?
                                    <Tables.Row>
                                        <Tables.Data colspan={4} className='text-center'>Belum ada data</Tables.Data>
                                    </Tables.Row>
                                    :
                                    <>
                                        {listData?.data?.map((post:any, index:number) => {
                                            return (
                                                <Tables.Row key={index}>
                                                    <Tables.Data className='text-right align-top'>{pageValue.offset + index + 1}</Tables.Data>
                                                    <Tables.Data className='align-top'>{post.group_name}</Tables.Data>
                                                    <Tables.Data className='align-top'>
                                                        { post.tenants.map((tenant:any, indexTenant: number) => {
                                                            return(
                                                                <div key={indexTenant}>
                                                                    {tenant.nama}
                                                                </div>
                                                            )
                                                        })}
                                                    </Tables.Data>
                                                    <Tables.Data className='align-top'>
                                                        <div className='flex flex-row gap-1'>
                                                            <Tooltip
                                                                tooltip={'Edit'}
                                                            >
                                                                <button onClick={() => handleEdit(post)} className='p-1 bg-orange-500 text-white rounded'><Icon icon='line-md:edit' /></button>
                                                            </Tooltip>
                                                            <Tooltip
                                                                tooltip={'Hapus'}
                                                            >
                                                                <button onClick={() => handleDelete(post)} className='p-1 bg-red-500 text-white rounded'><Icon icon='fluent-mdl2:delete'  /></button>
                                                            </Tooltip>
                                                        </div>
                                                    </Tables.Data>
                                                </Tables.Row>
                                            )
                                        })}
                                    </>
                                }
                            </Tables.Body>
                        </Tables>
                        <div className='mt-5'>
                            <PagePagination
                                data={pageValue.obj}
                                handlePagination={handlePaginate}
                                page={pageValue.page}
                                lastPage={pageValue.lastPage}
                            />
                        </div>
                    </>
                }
                { view === 'create' &&
                    <div className='grid grid-cols-12 gap-10'>
                        <div className='col-span-12 md:col-span-4'>
                            <FormTextSingleComp
                                {...formData.nama}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...formData.url_check_user}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...formData.url_post_register}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...formData.login_title}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                            <div className='mt-5'>
                                <FormTextSingleComp
                                    {...formData.logo}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-8 w-full">
                            <div className='flex flex-row gap-1 items-center'>
                                <div className='w-full bg-amber-100 text-amber-500 flex flex-row rounded-lg min-h-[70px] h-fit gap-3 items-center'>
                                    <div className='min-h-[50px] h-full w-[10px] bg-amber-500 rounded-l-lg'>     
                                    </div>
                                    <div className='py-2'>
                                        <div className='font-bold'>Tenant</div>
                                        <div className=''>
                                            Daftar Tenant dalam group
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                            <div className='flex flex-row justify-end mt-5'>
                                <Button color="primary" size="md" onClick={handleShowTenant}>Tambah Tenant</Button>
                            </div>
                            <div className="mt-10 flex flex-row justify-end w-full">
                                <ul className='w-full'>
                                    { listTenant.map((tenant:any, index:number) => {
                                        return(
                                            <li key={index} className='py-3 border-b border-b-gray-400'>
                                                <div className="flex flex-row justify-between">
                                                    <div>{tenant.nama}</div>
                                                    <div>
                                                        <Tooltip tooltip="Hapus tenant">
                                                            <button className='p-2 bg-red-500 text-white rounded-md' onClick={() => handleDeleteListTenant(index)}>
                                                                <Icon icon="uil:times" />
                                                            </button>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        
                            <div className='flex flex-row gap-1 items-center mt-10'>
                                <div className='flex flex-row gap-1 items-center'>
                                    <div className='w-full bg-blue-100 text-blue-500 flex flex-row rounded-lg min-h-[100px] h-fit gap-3 items-center'>
                                        <div className='min-h-[100px] h-full w-[10px] bg-blue-500 rounded-l-lg'>     
                                        </div>
                                        <div className='py-2 px-2'>
                                            <div className='font-bold'>Cutom Signup Field</div>
                                            <div className=''>
                                                ketika response dari check user false (is_register: False) maka field apa saja yang dibutuhkan.
                                                Untuk nama dan email tidak perlu ditambahkan lagi.
                                            </div>
                                        </div>
                                    </div>                                
                                </div>
                                
                            </div>
                            <div className='mt-5'>
                                <div className="flex flex-row justify-end">
                                    <Button color="success" onClick={handleAddCustomField}>Tambah Field</Button>
                                </div>
                                <div className='mt-5'>
                                    <Tables className='border-separate rounded-sm'>
                                        <Tables.Head className='bg-[#efeeee]'>
                                            <tr>
                                                <Tables.Header className='font-bold text-[13px] py-[10px] text-right'>#</Tables.Header>
                                                <Tables.Header className='font-bold text-[13px] py-[10px] w-[30%]'>Key (no special character, whitout space)</Tables.Header>
                                                <Tables.Header className='font-bold text-[13px] py-[10px] w-[30%]'>Label</Tables.Header>
                                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Type</Tables.Header>
                                                <Tables.Header className='font-bold text-[13px] py-[10px]'>Action</Tables.Header>
                                                
                                            </tr>
                                        </Tables.Head>
                                        <Tables.Body>
                                            { customField.map((field:any, index: number) => {  
                                                return(
                                                    <Tables.Row key={index}>
                                                        <Tables.Data className='text-right align-top'>{index + 1}</Tables.Data>
                                                        <Tables.Data className='align-top'>
                                                            <FormTextSingleComp
                                                                {...field.key_value}
                                                                onChange={(e) => handleChangeCustomField(index, e)}
                                                                onBlur={(e) => handleBlurCustomField(index, e)}
                                                            />
                                                        </Tables.Data>
                                                        <Tables.Data className='align-top'>
                                                            <FormTextSingleComp
                                                                {...field.label_value}
                                                                onChange={(e) => handleChangeCustomField(index, e)}
                                                                onBlur={(e) => handleBlurCustomField(index, e)}
                                                            />
                                                        </Tables.Data>
                                                        <Tables.Data className='align-top'>
                                                            <div>
                                                                <FormTextSingleComp
                                                                    {...field.type_value}
                                                                    onChange={(e) => handleChangeCustomField(index, e)}
                                                                    onBlur={(e) => handleBlurCustomField(index, e)}
                                                                />
                                                            </div>
                                                            { field.type_value.value === 'union' &&
                                                                <div>
                                                                    <FormTextSingleComp
                                                                        {...field.union_value}
                                                                        onChange={(e) => handleChangeCustomField(index, e)}
                                                                        onBlur={(e) => handleBlurCustomField(index, e)}
                                                                    />
                                                                </div>
                                                            }
                                                        </Tables.Data>
                                                        <Tables.Data className='align-top'>
                                                            <button className='p-1 bg-red-500 text-white rounded-md' onClick={() => handleDeleteCustomField(index)}>
                                                                <Icon icon="uil:times" />
                                                            </button>
                                                        </Tables.Data>
                                                    </Tables.Row>
                                                )
                                            })}
                                        </Tables.Body>
                                    </Tables>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Card>
        </>
    )
}
export default GroupTenantPage;