import { ThemeContext } from "../../context/ThemeContext";
import { useContext } from "react";
import useRipple from "use-ripple-hook";
import { ButtonRippleProps, themeTypeProps } from "../../@types/@types.theme";

const ButtonRipple = ({
	color,
	duration,
	cancelAutomatically,
	disabled=false,
	children,
	onClick=() => {},
	className,
	style,
	onMouseLeave,
	onMouseOver
	// ...rest
}: ButtonRippleProps) => {
	const { colorMode } = useContext(ThemeContext) as themeTypeProps;

	const [ripple, event] = useRipple({
		duration: duration || 500,
		color: color || colorMode === "light" ? "#00000030" : "#ffffff30",
		cancelAutomatically: cancelAutomatically || false,
		timingFunction: "cubic-bezier(.42,.36,.28,.88)",
		disabled: disabled,
	});
	return (
		<button
			// {...rest}
			// className={`${className} bg-blue-400`}
			className={`${className} bg-blue-400`}
			style={style}
			disabled={disabled}
			ref={ripple}
			onMouseDown={event}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseLeave}
			onClick={(e) => {
				e.stopPropagation();
				onClick && onClick(e);
			}}
		>
			{children}
		</button>
	);
};

export default ButtonRipple;
