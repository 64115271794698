type labelProps = {
    className: string,
    children?: React.ReactNode;
}

const Label = ({
    className,
    children,
} : labelProps) => {
    // const { className } = props;
    return(
        <label className={`tracking-wide text-gray-700 text-xs font-medium ${className} ${className && !className.includes('mb') && 'mb-2' }`} htmlFor="grid-first-name">
            {children}
        </label>
    )
}

export default Label;